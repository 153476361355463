import styled from "styled-components";

export const StyledAbout = styled.section`
  min-height: 50rem;
  /* background-color: rgb(238, 238, 238); */
  /* padding: 2rem 0; */
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5em;
  padding: 2rem 0;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    gap: 2rem;
  }
`;

export const StyledAboutSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 4%;

  &:nth-of-type(2) {
    gap: 2em;
    transform: translateY(5rem);
    opacity: 0;
    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;

    &.isActived {
      transform: translateY(0);
      opacity: 1;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 1em;
    p {
      color: #838383;
    }
    &.hobby {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 0;
    }
    &.min-h {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2em;
      row-gap: 0.5em;
      li {
        height: min-content;
      }
    }
    &.skill-img {
      gap: 0.5em;
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    gap: 2rem;
  }
`;

export const StyledAboutBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5em;
`;

export const StyledAboutChildSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1em;

  p {
    line-height: 2em;
  }

  span {
    font-weight: bolder;
  }

  label {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    color: #686868;
    user-select: none;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1rem;
      height: 1rem;
      color: #686868;
      background-color: #fff;
      border: 1px solid #aaaaaa;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  h4 {
    font-size: 1.2em;
  }
`;

export const StyledAboutResumenSection = styled.div`
  flex: 1 1 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const StyledAboutArticle = styled.article`
  flex: 1 1 20%;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;

  &.isActived {
    > div:nth-of-type(1) {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
    > div:nth-of-type(2) {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex: 1 1 40%;
  }
`;

export const StyledAboutIcon = styled.div`
  position: relative;
  margin: 0 auto;
  width: 6rem;
  padding: 1.5rem;
  z-index: 9;
  font-size: 3rem;
  justify-content: center;
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.colors.light};

  transform: translateY(5rem) scale(0);
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    width: 5rem;
    padding: 1.2rem;
    font-size: 2.5rem;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 4rem;
    padding: 1rem;
    font-size: 2rem;
  }
`;

export const StyledAboutHexagon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  span {
    border-radius: 2rem;
    z-index: -1;
    position: absolute;
    width: 58%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.main};

    &:nth-of-type(1) {
      transform: rotate(0);
    }
    &:nth-of-type(2) {
      transform: rotate(60deg);
    }
    &:nth-of-type(3) {
      transform: rotate(-60deg);
    }
  }
`;

export const StyledAboutText = styled.div`
  display: flex;
  flex-direction: column;
  transform: translateY(5rem);
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;
  gap: 1rem;

  p {
    color: #838383;
  }

  &.isActived {
    transform: translateY(0);
    opacity: 1;
  }
`;
