/*
Loading
Created by Sunil Park
*/

import styled, { keyframes } from "styled-components";

import { StyledUI, StyledBox, StyledUITextBox } from "./UI.styled";

const ani1 = keyframes`
0%{
  transform: rotate(0)
}
100% {
  transform: rotate(360deg)
}
`;

const StlyedParent = styled.div`
  position: relative;
  width: 6em;
  height: 6em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledChild = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  border: 0.4rem solid transparent;
  animation: 1s ${ani1} cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${({ theme }) => theme.colors.main}
    ${({ theme }) => theme.colors.main} transparent transparent;

  &:nth-of-type(1) {
    z-index: 3;
    animation-delay: 0s;
    filter: brightness(160%);
  }
  &:nth-of-type(2) {
    z-index: 2;
    animation-delay: -0.2s;
    filter: brightness(130%);
  }
  &:nth-of-type(3) {
    z-index: 1;
    animation-delay: -0.4s;
  }
`;

const LoadingUI = () => {
  return (
    <StyledUI>
      <StyledBox>
        <StlyedParent>
          <StyledChild />
          <StyledChild />
          <StyledChild />
        </StlyedParent>
      </StyledBox>
      <StyledUITextBox>
        <p>Loading - animation</p>
      </StyledUITextBox>
    </StyledUI>
  );
};

export default LoadingUI;
