import React from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
  padding: 2rem 1rem;
  background-color: ${({ theme }) => theme.colors.dark};
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  p {
    color: ${({ theme }) => theme.colors.light};
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <p>Portfolio 2024</p>
      <p>Developed by Sunil Park</p>
    </StyledFooter>
  );
};

export default Footer;
