import React, { useState, useEffect } from "react";
import throttle from "../helper/throttle";

export const useScroll = () => {
  const [currentScroll, setCurrentScroll] = useState(0);

  useEffect(() => {
    const scrollEventer = () => {
      throttle(() => {
        setCurrentScroll(document.documentElement.scrollTop);
      }, 250);
    };
    window.addEventListener("scroll", scrollEventer);
    return () => {
      window.removeEventListener("scroll", scrollEventer);
    };
  }, []);

  return {
    currentScroll,
  };
};
