/*
Information about me is recorded on this page.
content: Strengths, History, Skills
*/

import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useObserver from "../../hooks/useObserver";
import { StyledWrapper } from "../../components/styles/Wrapper.styled";
import { StyledHeader } from "../../components/styles/Header.styled";
import { LinkBox } from "../../components/LinkBox";
import { Resume } from "../../components/Resume";
import {
  StyledAbout,
  StyledContent,
  StyledAboutSection,
  StyledAboutBox,
  StyledAboutChildSection,
  StyledAboutResumenSection,
  StyledAboutArticle,
  StyledAboutIcon,
  StyledAboutHexagon,
  StyledAboutText,
} from "./About.styled";

import { keywords, skills, hobby } from "./data";
import Photo from "./Photo";
import {
  StyledLineBottom,
  StyledLineTop,
} from "../../components/styles/GlobalLine.styled";

const About = () => {
  const [viewByText, setViewByText] = useState(false);
  const targetRefHeader = useRef(null);
  const targetRefOne = useRef(null);
  const targetRefTwo = useRef(null);
  const defaultOption = {
    root: null,
    rootMargin: "0px 0px 0px 0px",
    threshold: 0.1,
  };
  const isVisibleHeader = useObserver(defaultOption, targetRefHeader, false);
  const isVisibleOne = useObserver(defaultOption, targetRefOne, false);
  const isVisibleTwo = useObserver(defaultOption, targetRefTwo, false);

  /* Display Developer Strengths Keywords */
  const displayKeywords = () => {
    return (
      <StyledAboutSection ref={targetRefOne}>
        {keywords.map(({ title, desc, icon }) => {
          return (
            <StyledAboutArticle
              className={`${isVisibleOne && "isActived"}`}
              key={title}
            >
              <StyledAboutIcon>
                <FontAwesomeIcon icon={icon} />
                <StyledAboutHexagon>
                  <span></span>
                  <span></span>
                  <span></span>
                </StyledAboutHexagon>
              </StyledAboutIcon>
              <StyledAboutText>
                <h3>{title}</h3>
                <p>{desc}</p>
              </StyledAboutText>
            </StyledAboutArticle>
          );
        })}
      </StyledAboutSection>
    );
  };

  /* Display About Developer(me) */
  const displayAbout = () => {
    return (
      <StyledAboutSection
        ref={targetRefTwo}
        className={`${isVisibleTwo && "isActived"}`}
      >
        <StyledAboutBox>
          {/* Photos about me */}
          {/* <Photo /> */}

          {/* About Me */}
          {/* <StyledAboutChildSection>
            <h2>A LITTLE ABOUT ME</h2>
            <p>
              Hi, my name is <span>Sunil Park</span>. I'm a{" "}
              <span>front-end developer</span> based in New Jersey. When I was{" "}
              <span>looking for a job</span> after graduating from university, I
              was thinking about what kind of job I really wanted. I recalled my{" "}
              <span>hobby</span> of making small apps from a young age and found
              that <span>making a website</span> was right for me. So I studied
              and created projects for <span>two years</span> to become a web
              developer. I'm <span>ready</span> to show my skills and excited to
              work with an amazing team.
            </p>
          </StyledAboutChildSection> */}

          {/* Skills */}
          <StyledAboutChildSection>
            <h4>MY TECHNICAL TOOLBOX</h4>
            <label>
              Read as text
              <button onClick={() => setViewByText((prev) => !prev)}>
                {viewByText ? <>&#x2713;</> : ""}
              </button>
            </label>
            {viewByText ? (
              <ul className="min-h">
                {skills.map(({ label }) => {
                  return (
                    <li key={label}>
                      <p>{label}</p>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <ul className="min-h skill-img">
                {skills.map(({ label, img }) => (
                  <li key={label}>
                    <img src={img} alt={label} title={label} />
                  </li>
                ))}
              </ul>
            )}
          </StyledAboutChildSection>

          {/* <StyledAboutChildSection>
            <h2>Technical Competencies</h2>
            <ul>
              <li>
                Crafting <span>responsive web designs</span> for any website
              </li>
              <li>
                Transforming wireframes and design mockups into{" "}
                <span>functional UI components</span>
              </li>
              <li>
                Enhancing web <span>performance effectively</span> on the
                frontend
              </li>
              <li>
                Proficient in independent <span>problem-solving</span>
              </li>
              <li>
                Rapid learning ability with a keen{" "}
                <span>understanding of algorithms</span>
              </li>
            </ul>
          </StyledAboutChildSection> */}

          {/* Hobby */}
          <StyledAboutChildSection>
            <h4>MY INTERESTS</h4>
            <ul className="hobby">
              {hobby.map((item) => {
                return (
                  <li key={item}>
                    <p>{item}</p>
                  </li>
                );
              })}
            </ul>
          </StyledAboutChildSection>
        </StyledAboutBox>
        <StyledAboutResumenSection>
          <LinkBox />
          <Resume />
        </StyledAboutResumenSection>
      </StyledAboutSection>
    );
  };

  return (
    <StyledAbout id="skills" ref={targetRefHeader}>
      <StyledWrapper>
        <StyledHeader
          ref={targetRefHeader}
          className={`${isVisibleHeader && "isActived"}`}
        >
          <h2>SKILLS</h2>
          <span></span>
        </StyledHeader>
        <StyledContent>
          {displayKeywords()}
          {displayAbout()}
        </StyledContent>
      </StyledWrapper>
    </StyledAbout>
  );
};

export default About;
