import React from "react";
import {
  StyledProjectsWorks,
  StyledProjectsArticleContent,
  StyledProjectsImgBox,
  StyledProjectsImgDesktop,
  StyledProjectsImgMobile,
  StyledProjectsContentTitle,
  StyledProjectTextContainer,
  StyledProjectTitle,
  StyledProjectsDescription,
} from "../Projects.styled";
import { IMAGE_KEY } from "../../../keys/keys";
const ProjectsMain = ({ lists, title, getData }) => {
  return (
    <>
      <StyledProjectTitle>{title}</StyledProjectTitle>
      <StyledProjectsWorks>
        {lists.map((item) => {
          return (
            <StyledProjectsArticleContent
              className="project-item"
              key={item.title}
              onClick={() => getData(item)}
              onKeyDown={(e) => e.key === "Enter" && getData(item)}
              tabIndex={0}
            >
              <StyledProjectsImgBox>
                <StyledProjectsImgDesktop>
                  <img
                    src={IMAGE_KEY + item.front_post[0] + "?tr=w-600"}
                    alt={item.title}
                  />
                </StyledProjectsImgDesktop>
                <StyledProjectsImgMobile>
                  <img
                    src={IMAGE_KEY + item.mobile_post + "?tr=w-200"}
                    alt={item.title}
                  />
                </StyledProjectsImgMobile>
              </StyledProjectsImgBox>
              <StyledProjectTextContainer>
                <StyledProjectsContentTitle>
                  <h3>{item.title}</h3>
                  <span>{item.category}</span>
                </StyledProjectsContentTitle>
                <StyledProjectsDescription>
                  <p>{item.summary}</p>
                </StyledProjectsDescription>
                <StyledProjectsDescription>
                  {item.tech.map((item) => (
                    <div className="tech" key={item}>
                      {item}
                    </div>
                  ))}
                </StyledProjectsDescription>
              </StyledProjectTextContainer>
            </StyledProjectsArticleContent>
          );
        })}
      </StyledProjectsWorks>
    </>
  );
};

export default ProjectsMain;
