import {
  // LoadingUI,
  // BoxsUI,
  CardUI,
  HoverUI,
  ToggleButton,
  Letter,
  // TextTransparent,
  Mobile,
  Line,
  // TypeAimation,
  NameUI,
} from "./UI/UI";

export const lists = [
  {
    title: "Spotless Cleaners NJ",
    front_post: [
      "/spotless-cleaners/scn1.png",
      "/spotless-cleaners/scn2.png",
      "/spotless-cleaners/scn4.png",
      "/spotless-cleaners/scn8.png",
      "/spotless-cleaners/scn3.png",
      "/spotless-cleaners/scn7.png",
    ],
    mobile_post: "/spotless-cleaners/scn11.png",
    desc: [
      "Developed a fully functional Next.js website independently, swiftly delivering a client-satisfactory solution that met all project requirements and earned high client approval.",
      "Implemented SEO best practices including semantic tags, metadata optimization, sitemap creation, and integration with Google tools (Search Console, Analytics, Business Profile).",
      "Designed a mobile-friendly, responsive UI/UX tailored to client's needs and target audience.",
    ],
    category: "Dry Cleaners",
    tech: [
      "Next.js",
      "Vercel",
      "Google Search Console",
      "Google Analytics",
      "Google Business Profile",
    ],
    summary:
      "The dry cleaners website built with Next.js. To improve SEO, semantic tags, metadata, and Google services have been used. The project mainly involved implementing all of the client's requirements.",
    link: "https://spotlesscleaners-nj.com/",
    github: "https://github.com/SunilPark1129/nj-spotlesscleaners",
  },
  {
    title: "SIP Clothing",
    front_post: [
      "/project/1-4.png",
      "/project/1-3.png",
      "/project/1-1.png",
      "/project/1-5.png",
    ],
    mobile_post: "/project/1-2.png",
    desc: [
      "Developed a user-friendly website aimed at significantly enhancing the online shopping experience.",
      "Implemented shopping cart functionality utilizing React.js and Redux for efficient state management and cookie storage, ensuring seamless browsing.",
      "Optimized web performance by curating and optimizing clothing images for faster loading times.",
      "Created an algorithm for fast, precise image searches by combining endpoints and queries.",
    ],
    category: "Online Shopping",
    tech: [
      "React",
      "React-Router",
      "SCSS",
      "TypeScript",
      "REST-API",
      "Redux",
      "Cookie",
      "Imagekit.io",
      "Netlify",
    ],
    summary:
      "The demo version of a clothing brand website built using React and Redux. By using state management and cookies, it implements features such as selecting items, favorite lists, and adding to the cart, enhancing the user experience.",
    link: "https://sip-clothing.com/",
    github: "https://github.com/SunilPark1129/sp-fashion",
  },
  {
    title: "Summary AI",
    front_post: [
      "/summary-ai/sa9.png",
      "/summary-ai/sa1.png",
      "/summary-ai/sa2.png",
      "/summary-ai/sa4.png",
      "/summary-ai/sa5.png",
      "/summary-ai/sa6.png",
    ],
    mobile_post: "/summary-ai/sa7.png",
    desc: [
      "When users upload an image of a long document, the AI provides a concise summary.",
      "Users can choose their preferred language, and the AI will translate it for them.",
      "I worked on this project because my mother struggles to understand English when reading mails.",
    ],
    category: "Dry Cleaners",
    tech: [
      "React",
      "OpenAI-GPT",
      "REST-API",
      "SCSS",
      "NodeJS",
      "Netlify",
      "Figma",
    ],
    summary:
      "The AI automatically summarizes long documents and translates them into your desired language.",
    link: "https://sp-summary-ai.netlify.app/",
    github: "https://github.com/SunilPark1129/Summary-AI",
  },
  {
    title: "C Movie Today",
    front_post: [
      "/project/2-1.png",
      "/project/2-3.png",
      "/project/2-4.png",
      "/project/2-5.png",
    ],
    mobile_post: "/project/2-2.png",
    desc: [
      "Designed a movie-oriented website with features for recommendations and searching",
      "Used Redux for efficient state management, enhancing overall web performance",
      "Implemented the debounce method to prevent server overload during multiple search requests.",
      "Utilized IntersectionObserver's scroll method to enable automatic fetching of new movie lists as the user scrolls to the last index of displayed items.",
    ],
    category: "Searching Movies",
    tech: ["React", "React-Router", "SCSS", "REST-API", "Redux", "Netlify"],
    summary:
      "The movie title search application built with React and Redux. It uses The Movie Database API to fetch data and present the results to the user. Users can retrieve information on up to 20 movies per request and load additional movie information from subsequent pages as needed.",
    link: "https://cmovietoday.com/",
    github: "https://github.com/SunilPark1129/c-movie-today",
  },
  {
    title: "Typestr",
    front_post: [
      "/project/5-1.png",
      "/project/5-3.png",
      "/project/5-4.png",
      "/project/5-5.png",
    ],
    mobile_post: "/project/5-2.png",
    desc: [
      "A game website that requires users to type the alphabet quickly without typos.",
      "Used MongoDB to post, get, and delete users’ records",
      "Data stored at various times within an array as users progress through the game, and displayed results once the game is completed",
      "Several animation effects added to help users to understand the UI when experiencing the game.",
    ],
    category: "Typing Game",
    tech: [
      "React",
      "Styled-Components",
      "REST-API",
      "NodeJS",
      "MongoDB",
      "Mongoose",
      "Database",
      "Netlify",
    ],
    summary:
      "The game web app built with React, Node.js for the backend, and MongoDB. When a user submits their game records through the API, the server adds the records to the database. CSS and JavaScript were used to provide visually smooth effects for users playing the game.",
    link: "https://sp-typestr.netlify.app/",
    github: "https://github.com/SunilPark1129/typestr-frontend",
    backend: true,
  },
  {
    title: "OpenAI Image",
    front_post: ["/project/7-1.png", "/project/7-3.png", "/project/7-4.png"],
    mobile_post: "/project/7-2.png",
    desc: [
      "Created a website leveraging OpenAI's cutting-edge AI for generating images from user-inputted text",
      "Developed an intuitive interface enabling users to interact with AI tools, facilitating the generation of images based on both textual descriptions and uploaded images, thereby introducing groundbreaking functionality.",
      "Implemented error handling for API errors, ensuring smooth operation and user experience.",
      "Integrated the Exif library to manage the orientation value of user-uploaded photos for proper display.",
    ],
    category: "OpenAI GPT",
    tech: [
      "React",
      "SCSS",
      "REST-API",
      "EXIF",
      "OpenAI-GPT",
      "NodeJS",
      "Netlify",
    ],
    summary:
      "The web app built with React where an AI draws pictures. It interacts with AI using the official OpenAI API from the ChatGPT developer website. Based on the user's requests, the AI can either edit an existing picture or create an image from text. I also added a server to protect the API key.",
    link: "https://sp-ai-image.netlify.app/",
    github: "https://github.com/SunilPark1129/OpenAI-Image",
    backend: true,
  },
  // {
  //   title: "Remember Vocab",
  //   front_post: [
  //     "/project/6-1.png",
  //     "/project/6-3.png",
  //     "/project/6-4.png",
  //     "/project/6-5.png",
  //   ],
  //   mobile_post: "/project/6-2.png",
  //   desc: "This is a web app that helps users easily memorize and study vocabularies.",
  //   category: "Vocabulary Storage Website",
  //   tech: [
  //     "React",
  //     "SCSS",
  //     "TypeScript",
  //     "Zustand",
  //     "REST-API",
  //     "Local-Storage",
  //   ],
  //   link: "https://sp-remeber-vocab.netlify.app/",
  //   github: "https://github.com/SunilPark1129/remember-vocab",
  // },
  // {
  //   title: "Spotless Cleaners",
  //   front_post: [
  //     "/project/12-1.png",
  //     "/project/12-3.png",
  //     "/project/12-4.png",
  //     "/project/12-5.png",
  //   ],
  //   mobile_post: "/project/12-2.png",
  //   desc: "Spotless Cleaners provides friendly services to assist customers with clothing alterations and laundry. You can check the services offered on this website.",
  //   main: "Added only necessary features and contents.",
  //   category: "Laundromat Website",
  //   tech: ["React", "React-Router", "Styled-Components"],
  //   link: "https://sp-spotless.netlify.app",
  //   github: "https://github.com/SunilPark1129/re-spotless",
  // },
  // {
  //   title: "Kaya Garden",
  //   front_post: ["/project/13-1.png", "/project/13-3.png", "/project/13-4.png"],
  //   mobile_post: "/project/13-2.png",
  //   desc: "Kaya Garden is a Korean restaurant that focuses on traditional Korean dishes. It is designed with a simple layout that can be scrolled through on a single page.",
  //   category: "Restaurant Website",
  //   tech: ["React", "SCSS"],
  //   link: "https://sp-kayagarden.netlify.app",
  //   github: "https://github.com/SunilPark1129/re-kaya-garden",
  // },
];

export const threejs = [
  {
    title: "Car on a planet",
    front_post: [
      "/project/14-1.png",
      "/project/14-2.png",
      "/project/14-3.png",
      "/project/14-4.png",
    ],
    mobile_post: "/project/14-5.png",
    desc: [
      `"Car on a Planet" is a simple 3D website where a car moves forwards, backwards, left, and right on a planet's surface. This website is developed using React Three Fiber technology. You can move the car by using 'w', 'a', 's', and 'd'`,
      "This website features a 3D-designed car that navigates around a planet based on user interaction.",
      "Achieved immersive 3D effects through React Three Fiber powered by Three.js.",
      "Ensured high-quality visuals by meticulously crafting all featured models on the website.",
      "Implemented complex calculations for precise direction, angle, and acceleration of the car.",
    ],
    category: "Three-js Project",
    tech: ["React", "Three.js", "R3F", "Drei", "Netlify"],
    summary:
      "The web app built with React and R3F, a framework for Three.js. It is implemented in 3D, allowing users to move a car using arrow keys or navigate the scene with the mouse. All models used in this project were custom-made.",
    link: "https://carplanet.netlify.app/",
    github: "https://github.com/SunilPark1129/car-on-a-planet",
  },
  {
    title: "About Sunil",
    front_post: [
      "/project/16-1",
      "/project/16-2",
      "/project/16-3",
      "/project/16-4",
      "/project/16-5",
      "/project/16-6",
    ],
    mobile_post: "/project/16-7",
    desc: [
      `The 'About Sunil' website is a 3D project using React Three Fiber to showcase information about me.`,
      "Used the Drei library to enhance the lighting structure and the quality of 3D models.",
      "The Gsap library was used to precisely calculate and fix positions, allowing for smooth movement to coordinates when the user clicks.",
      "React was used to enable user interaction with Three.js.",
    ],
    category: "Three-js Project",
    tech: ["React", "Three.js", "R3F", "Drei", "Gsap", "Netlify"],
    summary:
      "The 3D web app called About Me, built with React and R3F. Users can explore a 3D room to discover information about my background. They can interact with custom-made models by clicking with the mouse. The camera positions were precisely arranged using Gsap library.",
    link: "https://sp-aboutme.netlify.app/",
    github: "https://github.com/SunilPark1129/sp-aboutme",
  },
];

export const UILists = [
  { title: "3D Card - drag", Component: CardUI },
  // { title: "3D Boxs - hover", Component: BoxsUI },
  { title: "SVG Line - hover", Component: Line },
  { title: "SVG Animation - hover", Component: Mobile },
  { title: "Letters - input", Component: Letter },
  // { title: "Typewriter", Component: TypeAimation },
  { title: "Hexagon Animation - hover", Component: HoverUI },
  { title: "Menu Icon - click", Component: ToggleButton },
  { title: "SVG Arrayoffset - hover", Component: NameUI },
  // { title: "Transparent Text - animation", Component: TextTransparent },
  // { title: "Loading - animation", Component: LoadingUI },
];
