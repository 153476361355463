import styled from "styled-components";

export const StyledProjects = styled.section`
  position: relative;
`;

export const StyledProjectsContainer = styled.div`
  display: grid;
  gap: 1em;
  transform: translateY(5rem);
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;
  position: relative;
  pointer-events: none;
  border-radius: 12px;

  &.isActived {
    transform: translateY(0);
    opacity: 1;
    pointer-events: inherit;
  }
`;

export const StyledProjectTitle = styled.div`
  width: max-content;
  font-size: 1.5em;
  font-weight: bolder;
  color: ${({ theme }) => theme.colors.point};
  margin: auto;
  padding: 4rem 0;
`;

export const StyledProjectsGap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6em;
  padding: 2rem 0;
`;

export const StyledProjectsWorks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10em;
`;

export const StyledProjectUIWorks = styled(StyledProjectsWorks)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1em;

  @media (max-width: 760px) {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
`;

export const StyledProjectsArticle = styled.article`
  display: flex;
  background-color: ${({ theme }) => theme.colors.light};
  position: relative;
  margin: 0 auto;
`;

export const StyledProjectsArticleContent = styled(StyledProjectsArticle)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  min-height: 10rem;

  &:nth-of-type(2n) {
    flex-direction: row-reverse;
  }

  @media (max-width: 760px) {
    flex-direction: column !important;
    max-width: 30rem;
    margin: 0 auto;
    gap: 1em;
  }

  > div:not(:nth-last-of-type(1)) {
    transition: 0.3s filter;
  }
  :hover {
    outline: 2px solid black;

    > div:is(:nth-last-of-type(1)) {
      opacity: 1;
    }
  }
  :focus {
    outline: 4px solid black;
  }
`;

export const StyledProjectTextContainer = styled.div`
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1 1 50%;
  min-height: 309.88px;

  @media (max-width: 760px) {
    padding: 0;
    flex: auto;
    min-height: auto;
  }
`;

export const StyledProjectsDescription = styled.div`
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;

  p {
    color: #828282;
  }

  .tech {
    font-size: 0.8em;
    padding: 0.5em 1em;
    background-color: #888888;
    color: #fff;
    white-space: nowrap;
    border-radius: 4px;
  }

  &:nth-of-type(2) {
    /* margin-top: auto; */
  }
`;

export const StyledProjectsContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25em;
  h3 {
    font-size: 1.5em;
    color: #2316ad;
  }
  span {
    color: #7e7e7e;
  }
`;

export const StyledProjectsArticleUI = styled.div`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.light};
  position: relative;
  display: block;
  margin: 0;
  max-height: none;
`;

export const StyledProjectsImgBox = styled.div`
  width: 100%;
  flex: 1 1 50%;
  max-width: 550px;
  aspect-ratio: 16 / 9;
  position: relative;
  border: 1px solid #b5b5b5;
`;

export const StyledProjectsImgDesktop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
`;

export const StyledProjectsImgMobile = styled.div`
  position: absolute;
  right: 1em;
  bottom: 1em;
  width: 4.5em;
  height: 8em;
  background-color: #1f1f1f;
  border-radius: 6px;
  outline: 1px solid white;
  box-shadow: 0px 0px 10px -3px #fff;
  z-index: 4;

  img {
    outline: 1px solid #9c9c9c;
    filter: brightness(80%);
    position: absolute;
    object-position: center;
    object-fit: cover;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 94%;
    border-radius: 6px;
  }
`;

export const StyledProjectsArticleText = styled.div`
  flex: 1 1 100%;
  text-align: center;
  p {
    color: ${({ theme }) => theme.colors.point};
  }
`;

export const StyledProjectModal = styled.article`
  position: fixed;
  left: 50%;
  top: 50%;
  width: 95%;
  max-height: 90%;
  background-color: ${({ theme }) => theme.colors.light};
  transform: translate(-50%, -50%);
  z-index: 999;
  max-width: 50em;
  overflow-y: auto;
`;

export const StyledProjectModalImage = styled.div`
  display: flex;
  cursor: grab;
  background-color: black;
  aspect-ratio: 16/9;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
  }
`;

export const StyledProjectModalText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em;
  min-height: 16em;
  gap: 2em;

  .modal__header {
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 1.4em;
    }
    p {
      font-size: 1em;
      color: #838383;
    }
  }
  .modal__desc {
    display: flex;
    flex-direction: column;
    gap: 1em;

    ol {
      display: flex;
      flex-direction: column;
      gap: 1em;
    }

    li {
      list-style: circle;
      list-style-position: inside;
      line-height: 1.5em;
      color: #838383;
    }
  }

  .modal__tech-used {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25em;

    p {
      padding: 0.5em 0.75em;
      border-radius: 2px;
      font-size: 0.8em;
      background-color: #888888;
      color: #fff;
    }
  }
  .modal__btns {
    display: flex;
    gap: 0.5em;

    a {
      display: flex;
      gap: 1em;
      align-items: center;
      background-color: #df6262;
      font-weight: bold;
      color: #fff;
      padding: 0.5em 1em;
      font-size: 0.9em;
      &:hover {
        background-color: #dd2c2c;
      }
    }
    button {
      display: flex;
      margin-left: auto;
      align-items: center;
      padding: 0.5em 1em;
      border: none;
      background-color: #8b8b8b;
      color: #fff;
      cursor: pointer;
      font-size: 0.9em;

      &:hover {
        background-color: #444444;
      }
    }
  }
  @media (max-width: 700px) {
    padding: 1em;
    .modal__header {
      padding: 0.5em 0;
    }
    .modal__desc {
      padding: 0.25em 0;
    }
  }
`;
