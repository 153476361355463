/*
Text Transparent
Created by Sunil Park
*/

import React from "react";
import styled, { keyframes } from "styled-components";
import { StyledUI, StyledBox, StyledUITextBox } from "./UI.styled";
import image from "../../../assets/text-transparent.jpg";

const StyledParent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  background: ${({ theme }) => theme.colors.dark};
`;

// back image appears
const backgroundAni = keyframes`
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

// background image
const StyledBackground = styled.div`
  z-index: 8;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: url(${image});
  background-size: cover;
  animation: ${backgroundAni} 3s ease-in-out infinite;
`;

// text transparent becomes non-transparent
const textAni = keyframes`
  0% {
    font-size: 2000px;
  }
  40% {
    color: transparent;
    font-size: 40px;
  }
  60% {
  }
  70% {
    color: rgb(240, 240, 240);
    text-shadow: 0px 0px 3px transparent;
    opacity: 1;
  }
  90% {
    color:rgb(240, 240, 240);
    text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.507);
    font-size: 40px;
  }
  100% {
    color: transparent;
  }
`;

// text fill color + backgroundimage clip
const StyledText = styled.p`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 9;
  font-weight: bold;
  pointer-events: none;
  background: url(${image});
  background-size: cover;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: ${textAni} 3s ease-in-out infinite;
`;

const TextTransparent = () => {
  return (
    <StyledUI>
      <StyledBox>
        <StyledParent>
          <StyledBackground />
          <StyledText>STYLE</StyledText>
        </StyledParent>
      </StyledBox>
      <StyledUITextBox>
        <p>Transparent Text - animation</p>
      </StyledUITextBox>
    </StyledUI>
  );
};

export default TextTransparent;
