import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
