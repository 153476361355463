/*
It serves as a navigation on the side.
Click on a link to jump to that section.
*/

import React from "react";
import useObserver from "../../hooks/useObserver";
import { HashLink as Link } from "react-router-hash-link";
import { StyledAside } from "./Navbar.styled";

const NavbarAside = ({ isOnload, targetArray }) => {
  const observerOption = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  };
  const isVisibleHome = useObserver(observerOption, targetArray[0], true);
  const isVisibleAbout = useObserver(observerOption, targetArray[2], true);
  const isVisibleProjects = useObserver(observerOption, targetArray[1], true);
  const isVisibleContact = useObserver(observerOption, targetArray[3], true);

  return (
    <StyledAside className={`${isOnload && "hasLoad"}`}>
      <Link to="/#home" smooth className={`${isVisibleHome && "isActived"}`}>
        <span>HOME</span>
      </Link>
      <Link
        to="/#projects"
        smooth
        className={`${isVisibleProjects && "isActived"}`}
      >
        <span>PROJECTS</span>
      </Link>
      <Link to="/#skills" smooth className={`${isVisibleAbout && "isActived"}`}>
        <span>SKILLS</span>
      </Link>
      <Link
        to="/#contact"
        smooth
        className={`${isVisibleContact && "isActived"}`}
      >
        <span>CONTACT</span>
      </Link>
    </StyledAside>
  );
};

export default NavbarAside;
