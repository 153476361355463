import React from "react";
import {
  StyledProjectUIWorks,
  StyledProjectsArticleUI,
  StyledProjectTitle,
} from "./Projects.styled";

const ProjectsUI = ({ UILists, title }) => {
  return (
    <>
      <StyledProjectTitle>{title}</StyledProjectTitle>
      <StyledProjectUIWorks>
        {UILists.map(({ title, Component }) => {
          return (
            <StyledProjectsArticleUI key={title}>
              <Component />
            </StyledProjectsArticleUI>
          );
        })}
      </StyledProjectUIWorks>
    </>
  );
};

export default ProjectsUI;
