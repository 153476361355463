import styled from "styled-components";

export const StyledGlobalBtn = styled.div`
  display: inline-block;
  border-radius: 0.3rem;
  background-color: ${({ theme }) => theme.colors.point};
  margin: 0.5em 0em;

  a {
    font-size: 1rem;
    display: flex;
    padding: 1em 2em;
    color: white;
  }

  &:hover {
    filter: brightness(110%);
    /* background-color: ${({ theme }) => theme.colors.dark}; */
  }
`;
