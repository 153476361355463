/*
Hover
Created by Sunil Park
*/

import styled from "styled-components";
import { StyledBox, StyledUI, StyledUITextBox } from "./UI.styled";

const StyledParent = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 14em;
  height: 14em;

  p {
    color: ${({ theme }) => theme.colors.main};
    font-weight: bold;
    padding: 0.5rem;
    opacity: 1;
    transition: 0.3s opacity;
  }

  &:hover {
    p {
      opacity: 0;
    }
    > div {
      width: 60%;

      span {
        transform: rotate(90deg);
        background-color: ${({ theme }) => theme.colors.main};
        border: 1px solid transparent;
        height: 100%;
        width: 100%;
        border-radius: 0;
        opacity: 1;
        transition-property: border-radius, height, width, transform, opacity,
          border, background-color;
        transition-duration: 0.5s, 0.5s, 0.5s, 0.3s, 0.5s, 0.5s, 0.5s;
        transition-delay: 0s, 0s, 0.3s, 1s, 0s, 1s, 0.8s;
      }
    }
  }
`;

const StyledChild = styled.div`
  transition-property: width;
  transition-duration: 0.3s;
  position: absolute;
  width: 100%;
  height: 35%;
  span {
    content: "";
    border: 1px solid ${({ theme }) => theme.colors.main};
    position: absolute;
    height: 10px;
    width: 10px;
    opacity: 0;
    background: white;
    border-radius: 50%;
    transition-property: border-radius, height, width, transform, opacity,
      border, background-color;
    transition-duration: 0.3s, 0.3s, 0.3s, 0.3s, 0.3s, 0.3s, 0.3s;
    transition-delay: 0s, 0s, 0s, 0s, 0.3s, 0s, 0s;
  }
  &:nth-of-type(1) {
    transform: rotate(-60deg);
  }
  &:nth-of-type(2) {
    transform: rotate(0deg);
  }
  &:nth-of-type(3) {
    transform: rotate(60deg);
  }
  &:nth-of-type(4) {
    transform: rotate(120deg);
  }
  &:nth-of-type(5) {
    transform: rotate(180deg);
  }
  &:nth-of-type(6) {
    transform: rotate(240deg);
  }
`;

const HoverUI = () => {
  return (
    <StyledUI>
      <StyledBox>
        <StyledParent>
          <p>Hover Me!</p>
          <StyledChild>
            <span></span>
          </StyledChild>
          <StyledChild>
            <span></span>
          </StyledChild>
          <StyledChild>
            <span></span>
          </StyledChild>
          <StyledChild>
            <span></span>
          </StyledChild>
          <StyledChild>
            <span></span>
          </StyledChild>
          <StyledChild>
            <span></span>
          </StyledChild>
        </StyledParent>
      </StyledBox>
      <StyledUITextBox>
        <p>Hexagon Animation - hover</p>
      </StyledUITextBox>
    </StyledUI>
  );
};

export default HoverUI;
