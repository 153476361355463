/*
Letters
Created by Sunil Park
*/

import React, { useState } from "react";
import styled from "styled-components";
import { StyledBox, StyledUI, StyledUITextBox } from "./UI.styled";

const StyledParent = styled.div`
  position: relative;
  width: 8em;
  height: 16px;
  display: flex;
  justify-content: center;
`;

const StyledChild = styled.div`
  width: 1rem;
  height: 16px;
  overflow: hidden;
  pointer-events: none;
  user-select: none;

  > div {
    font-size: 14px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 1rem;
    transition: transform 0.5s cubic-bezier(1, 0.56, 0.63, 1.43);
  }
`;

const Letter = () => {
  // units for a to z letters with "_" character
  const units = {
    _: 0,
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    F: 6,
    G: 7,
    H: 8,
    I: 9,
    J: 10,
    K: 11,
    L: 12,
    M: 13,
    N: 14,
    O: 15,
    P: 16,
    Q: 17,
    R: 18,
    S: 19,
    T: 20,
    U: 21,
    V: 22,
    W: 23,
    X: 24,
    Y: 25,
    Z: 26,
  };

  // initial letters
  // return value ["L","E","T","T","E","R","S"]
  const initialLetters = [12, 5, 20, 20, 5, 18, 19];

  // display the letters by matching the Units Object
  // get values from user's input
  const [letters, setLetters] = useState(initialLetters);

  function changeHandler(e) {
    const value = e.target.value.toUpperCase();

    // if user input is empty then return the initial value;
    if (value === "") return setLetters(initialLetters);

    let temp = [...letters];

    for (let i = 0; i < temp.length; i++) {
      // if the letter is inside of the Unit Object then get the number
      if (value[i] !== undefined && value[i].match(/[A-Z]/i)) {
        temp[i] = units[value[i]];
      } else {
        temp[i] = 0;
      }
    }
    setLetters(temp);
  }

  return (
    <StyledUI>
      <StyledBox>
        <StyledParent>
          {letters.map((_, idx) => {
            return (
              <StyledChild key={idx}>
                {Object.keys(units).map((item, key) => {
                  return (
                    <div
                      key={key}
                      style={{
                        transform: `translateY(-${letters[idx] * 16}px)`,
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
              </StyledChild>
            );
          })}
        </StyledParent>
      </StyledBox>
      <StyledUITextBox>
        <p>Letters - input</p>
        <input type="text" onChange={changeHandler} maxLength="7" />
      </StyledUITextBox>
    </StyledUI>
  );
};

export default Letter;
