/*
When starting the page it will prevent the scroll to display the animation
*/

import { useState, useEffect } from "react";

export const OnLoad = () => {
  const [isOnload, setOnload] = useState(false);

  useEffect(() => {
    setOnload(true);
  }, []);

  useEffect(() => {
    if (isOnload) {
      setTimeout(() => {
        document.body.classList.remove("prevent-scroll");
      }, 1600);
    }
  }, [isOnload]);

  return { isOnload };
};
