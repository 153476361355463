import styled from "styled-components";

export const StyledContact = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: rgb(238, 238, 238);
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5em;
  align-items: center;
  padding-bottom: 4rem;
`;

export const StyledContentTextBox = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 2em;
  border-radius: 0.5rem;
  max-width: 30rem;
  width: 100%;
  padding: 2rem 1rem;
  box-shadow: 0px 3px 10px -5px ${({ theme }) => theme.colors.dark};
  background-color: ${({ theme }) => theme.colors.light};

  transform: translateY(5rem);
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;

  &.isActived {
    transform: translateY(0);
    opacity: 1;
  }

  h3 {
    font-size: 1.1em;
  }
`;

export const StyledDotted = styled.div`
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: rgb(238, 238, 238);
  top: 0.5rem;
  box-shadow: 2px 2px 2px #00000073, 2px 2px 2px #00000073 inset;
  border-radius: 50%;

  &:nth-of-type(1) {
    left: 0.5rem;
  }
  &:nth-of-type(2) {
    right: 0.5rem;
  }
`;

export const StyledContentForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 26rem;

  > div {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    label {
      margin-right: auto;
      color: #505050;
      font-size: 0.8em;
    }
    input,
    textarea {
      padding: 0.5rem 0.8rem;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1em;
      color: #474747;
    }
    textarea {
      resize: none;
    }
  }

  button {
    padding: 1em 2em;
    margin: auto;
    background-color: #fff;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.font};
    border: 1px solid #b1b1b1;
    border-radius: 4px;
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.point};
      color: #fff;
    }
  }
`;

export const StyledContentLinkBox = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 1rem;

  > div {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    text-align: start;

    span {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.point};
    }
  }
  > div > div {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
`;
