import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import styled from "styled-components";

const StyledLinkBox = styled.div`
  display: flex;
  gap: 1rem;

  a {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.main};

    &:hover {
      color: ${({ theme }) => theme.colors.point};
    }
  }
`;

export const LinkBox = () => {
  return (
    <StyledLinkBox>
      <a
        href="https://www.linkedin.com/in/sunil-park-0723a5275/"
        title={"LinkedIn"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a
        href="https://github.com/SunilPark1129"
        title={"Github"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faGithub} />
      </a>
    </StyledLinkBox>
  );
};
