import React, { useState } from "react";
import { StyledUI, StyledBox, StyledUITextBox } from "./UI.styled";
import styled from "styled-components";

const StyledParent = styled.div`
  position: relative;
  width: 3rem;
  height: 2rem;
  cursor: pointer;

  &.activated div {
    transition-delay: 0s, 0s, 0.2s, 0.2s, 0.3s, 0.3s;
    &:nth-of-type(1) {
      width: 0.3rem;
      height: 100%;
      transform: translateY(-50%) translateX(-0.15rem) rotate(45deg);
      top: 50%;
      left: 50%;
    }
    &:nth-of-type(2) {
      height: 100%;
      width: 0.3rem;
      transform: translateY(-50%) translateX(0.15rem) rotate(-45deg);
      top: 50%;
      right: 50%;
    }
    &:nth-of-type(3) {
      height: 100%;
      width: 0.3rem;
      transform: translateY(-50%) translateX(-0.15rem) rotate(45deg);
      top: 50%;
      left: 50%;
    }
  }
`;

const StyledChild = styled.div`
  position: absolute;
  width: 100%;
  height: 0.3rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.main};
  transition-property: height, width, transform, top, left, right;
  transition-delay: 0s, 0s, 0s, 0s, 0s, 0s;
  transition-duration: 0.3s;
  &:nth-of-type(1) {
    left: 0;
    top: 0%;
    transform: translateY(0%);
  }
  &:nth-of-type(2) {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &:nth-of-type(3) {
    left: 0;
    top: 100%;
    transform: translateY(-100%);
  }
`;

const ToggleButton = () => {
  const [hasClicked, setHasClicked] = useState(false);
  function clickHandler() {
    setHasClicked((prev) => !prev);
  }
  return (
    <StyledUI>
      <StyledBox>
        <StyledParent
          className={`${hasClicked ? "activated" : ""}`}
          onClick={clickHandler}
        >
          <StyledChild />
          <StyledChild />
          <StyledChild />
        </StyledParent>
      </StyledBox>
      <StyledUITextBox>
        <p>Menu Icon - click</p>
      </StyledUITextBox>
    </StyledUI>
  );
};

export default ToggleButton;
