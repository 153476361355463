import React from "react";
import { StyledGlobalBtn } from "./styles/GlobalButton.styled";

export const Resume = () => {
  return (
    <StyledGlobalBtn title={"View resume"}>
      <a href="/sunilpark_resume.pdf" target="_blank">
        Resume
      </a>
    </StyledGlobalBtn>
  );
};
