/*
Typewriter Animation
Created by Sunil Park
*/

import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { StyledUI, StyledBox, StyledUITextBox } from "./UI.styled";

const StyledFrameRow = keyframes`
    0%,100% {
        width: 0%;
    }
    60%, 80% {
        width: 100%;
    }
`;

const StyledFrameCursor = keyframes`
    30% {
        border-color: transparent;
    }
    100% {
        border-color: #2b2b2b;
    }
`;

const StyledFrameCol = keyframes`
    100% {
        transform: translateY(-100%);
    }
    `;

const StyledParent = styled.div`
  overflow: hidden;
  height: 1.4em;

  > div {
    animation-name: ${StyledFrameCol};
    animation-iteration-count: infinite;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledChild = styled.div`
  width: fit-content;
  height: 1.4em;
  display: flex;
  align-items: center;
  > p {
    width: 100%;
    font-family: monospace;
    font-size: 1.2em;
    letter-spacing: 2px;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
    border-right: 2px solid transparent;
    animation: ${StyledFrameRow} 1.2s infinite,
      ${StyledFrameCursor} 0.6s infinite;
  }
`;

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.light};
  border: none;
  padding: 0.3em 0.5em;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.point};
  }
`;

const MIN_STAGE = 1;
const MAX_STAGE = 5;

const TypeAimation = () => {
  const [hasPlayed, setHasPlayed] = useState(true);

  const [finalValue, setFinalValue] = useState(["JavaScript"]);

  function stageClickHandler() {
    if (finalValue.length === MIN_STAGE) return;

    setFinalValue((prev) => prev.slice(0, prev.length - 1));

    setHasPlayed(false);
  }

  function submitHandler(e) {
    e.preventDefault();
    const { textval } = e.target;
    const value = textval.value;

    if (finalValue.length === MAX_STAGE) return;

    if (value.trim().length === 0) {
      setFinalValue((prev) => [...prev, "JavaScript"]);
    } else {
      setFinalValue((prev) => [...prev, value]);
      textval.value = "";
    }

    setHasPlayed(false);
  }

  return (
    <StyledUI>
      <StyledBox>
        <StyledParent
          style={hasPlayed ? {} : { overflow: "unset", height: "auto" }}
        >
          <div
            style={
              hasPlayed
                ? {
                    animationDuration: `${finalValue.length * 1.2}s`,
                    animationTimingFunction: `steps(${finalValue.length}, end)`,
                  }
                : { animation: "none" }
            }
          >
            {finalValue.map((item, idx) => (
              <StyledChild key={idx}>
                <p
                  style={
                    hasPlayed
                      ? {
                          animationTimingFunction: `steps(${finalValue[idx].length}, start), linear`,
                        }
                      : {
                          animation: "none",
                          outline:
                            finalValue.length - 1 === idx
                              ? "2px solid #cf2d2d"
                              : "none",
                        }
                  }
                >
                  {item}
                </p>
              </StyledChild>
            ))}
          </div>
        </StyledParent>
      </StyledBox>
      <StyledUITextBox>
        <p>Typewriter - input</p>
        <div style={{ display: "flex" }}>
          <form onSubmit={submitHandler} style={{ display: "flex" }}>
            <input
              type="text"
              name="textval"
              autoComplete="off"
              maxLength={14}
            />
            <StyledButton>Add</StyledButton>
          </form>
          <StyledButton onClick={() => stageClickHandler(-1)}>
            Delete
          </StyledButton>
        </div>
        <div>
          <StyledButton
            style={hasPlayed ? { backgroundColor: "#8b8b8b" } : {}}
            onClick={() => setHasPlayed(true)}
          >
            PLAY
          </StyledButton>
          <StyledButton
            style={!hasPlayed ? { backgroundColor: "#8b8b8b" } : {}}
            onClick={() => setHasPlayed(false)}
          >
            STOP
          </StyledButton>
        </div>
      </StyledUITextBox>
    </StyledUI>
  );
};

export default TypeAimation;
