import {
  faDisplay,
  faRepeat,
  faScrewdriverWrench,
  faBookOpen,
} from "@fortawesome/free-solid-svg-icons";

export const keywords = [
  {
    title: "Responsive",
    desc: "The websites I create are responsive on any device",
    icon: faDisplay,
  },
  {
    title: "Web-Performance",
    desc: "I always think about ways to improve web performance",
    icon: faRepeat,
  },
  {
    title: "Problem-Solving",
    desc: "I like finding problems and solving them",
    icon: faScrewdriverWrench,
  },
  {
    title: "Fast-Learner",
    desc: "I crave to learn new things and understand each algorithms",
    icon: faBookOpen,
  },
];

export const skills = [
  {
    label: "HTML",
    img: "https://img.shields.io/badge/html5-%23E34F26.svg?style=for-the-badge&logo=html5&logoColor=white",
  },
  {
    label: "CSS",
    img: "https://img.shields.io/badge/css3-%231572B6.svg?style=for-the-badge&logo=css3&logoColor=white",
  },
  {
    label: "Javascript",
    img: "https://img.shields.io/badge/javascript-%23323330.svg?style=for-the-badge&logo=javascript&logoColor=%23F7DF1E",
  },
  {
    label: "TypeScript",
    img: "https://img.shields.io/badge/typescript-%23007ACC.svg?style=for-the-badge&logo=typescript&logoColor=white",
  },
  {
    label: "React",
    img: "https://img.shields.io/badge/react-%2320232a.svg?style=for-the-badge&logo=react&logoColor=%2361DAFB",
  },
  {
    label: "Next.js",
    img: "https://img.shields.io/badge/Next-black?style=for-the-badge&logo=next.js&logoColor=white",
  },
  {
    label: "SCSS",
    img: "https://img.shields.io/badge/SCSS-hotpink.svg?style=for-the-badge&logo=SASS&logoColor=white",
  },
  {
    label: "Styled-Components",
    img: "https://img.shields.io/badge/styled--components-DB7093?style=for-the-badge&logo=styled-components&logoColor=white",
  },
  {
    label: "Tailwind CSS",
    img: "https://img.shields.io/badge/tailwindcss-%2338B2AC.svg?style=for-the-badge&logo=tailwind-css&logoColor=white",
  },
  {
    label: "Material-UI",
    img: "https://img.shields.io/badge/MUI-%230081CB.svg?style=for-the-badge&logo=mui&logoColor=white",
  },
  // {
  //   label: "Storybook",
  //   img: "https://img.shields.io/badge/-Storybook-FF4785?style=for-the-badge&logo=storybook&logoColor=white",
  // },
  {
    label: "Three.js",
    img: "https://img.shields.io/badge/threejs-black?style=for-the-badge&logo=three.js&logoColor=white",
  },
  {
    label: "R3F",
    img: "https://img.shields.io/badge/r3f-black?style=for-the-badge&logo=three.js&logoColor=white",
  },
  {
    label: "REST",
    img: "https://img.shields.io/badge/REST-%23CF4647.svg?style=for-the-badge&logoColor=white",
  },
  // {
  //   label: "GraphQL",
  //   img: "https://img.shields.io/badge/-GraphQL-E10098?style=for-the-badge&logo=graphql&logoColor=white",
  // },
  // {
  //   label: "Apollo-GraphQL",
  //   img: "https://img.shields.io/badge/-ApolloGraphQL-311C87?style=for-the-badge&logo=apollo-graphql",
  // },
  {
    label: "Redux",
    img: "https://img.shields.io/badge/redux-%23593d88.svg?style=for-the-badge&logo=redux&logoColor=white",
  },
  {
    label: "React-Query",
    img: "https://img.shields.io/badge/-React%20Query-FF4154?style=for-the-badge&logo=react%20query&logoColor=white",
  },
  {
    label: "Recoil",
    img: "https://img.shields.io/badge/recoil-%23323330.svg?style=for-the-badge&logoColor=%23F7DF1E",
  },
  {
    label: "Zustand",
    img: "https://img.shields.io/badge/zustand-%23323330.svg?style=for-the-badge&logoColor=%23F7DF1E",
  },
  {
    label: "Jest",
    img: "https://img.shields.io/badge/-jest-%23C21325?style=for-the-badge&logo=jest&logoColor=white",
  },
  // {
  //   label: "JSDoc",
  //   img: "https://img.shields.io/badge/JSDoc-%23323330.svg?style=for-the-badge&logoColor=%23F7DF1E",
  // },
  {
    label: "Node.js",
    img: "https://img.shields.io/badge/node.js-6DA55F?style=for-the-badge&logo=node.js&logoColor=white",
  },
  {
    label: "MySQL",
    img: "https://img.shields.io/badge/mysql-4479A1.svg?style=for-the-badge&logo=mysql&logoColor=white",
  },
  {
    label: "MongoDB",
    img: "https://img.shields.io/badge/MongoDB-%234ea94b.svg?style=for-the-badge&logo=mongodb&logoColor=white",
  },
  {
    label: "GitHub",
    img: "https://img.shields.io/badge/github-%23121011.svg?style=for-the-badge&logo=github&logoColor=white",
  },
  {
    label: "Git",
    img: "https://img.shields.io/badge/git-%23F05033.svg?style=for-the-badge&logo=git&logoColor=white",
  },
  {
    label: "Figma",
    img: "https://img.shields.io/badge/figma-%23F24E1E.svg?style=for-the-badge&logo=figma&logoColor=white",
  },
  {
    label: "Netlify",
    img: "https://img.shields.io/badge/netlify-%23000000.svg?style=for-the-badge&logo=netlify&logoColor=#00C7B7",
  },
  {
    label: "Vercel",
    img: "https://img.shields.io/badge/vercel-%23000000.svg?style=for-the-badge&logo=vercel&logoColor=white",
  },
];

export const hobby = [
  "Coding",
  "Communicating",
  "Drawing / painting",
  "Learning about histories",
  "Learning about cultures",
  "Trying new foods",
  "Hiking",
  "Playing video games",
  "Playing tennis / ping pong",
  "Watching Youtube",
  "Editing videos / photos",
];
