import styled from "styled-components";

export const StyledWrapper = styled.div`
  display: flex;
  max-width: 1280px;
  width: 100%;
  flex-direction: column;
  margin: auto;
  padding: 10rem 2em;
  min-width: 260px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 4rem 2em;
  }
`;
