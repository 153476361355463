import styled from "styled-components";

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  align-items: center;
  padding: 1rem;

  transform: translateY(5rem);
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;

  font-size: 1.5em;

  &.isActived {
    transform: translateY(0);
    opacity: 1;
  }

  span {
    content: "";
    width: 2rem;
    height: 0.3rem;
    background-color: ${({ theme }) => theme.colors.main};
    border-radius: 0.5rem;
    transition: 1s background-color ease-in-out;
  }

  h2 {
    font-size: 1.2em;
  }
`;
