/*
Portfolio made in 2023
By Sunil Park

Design -
I designed eggs as the main concept.
The reason is that I am entry-level on the frontend, like a chick that has just woken up from the egg.
*/

import React from "react";
import Pages from "./pages/Pages";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import { GlobalStyles } from "./components/styles/Global.styled";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "./components/styles/Theme";
import { OnLoad } from "./helper/OnLoad";

// When isOnload variable is true will remove the class name
document.body.classList.add("prevent-scroll");

function App() {
  const { isOnload } = OnLoad();
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <Router>
        <Navbar isOnload={isOnload} />
        <Pages isOnload={isOnload} />
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
