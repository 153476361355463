/*
Main Cover Page
Displaying developer's name and desire job position
*/

import React from "react";
import { StyledWrapper } from "../../components/styles/Wrapper.styled";
import {
  StyledHome,
  StyledHomeContent,
  StlyedHomeTitle,
  StyledHomeTextContainer,
  StyledHomeBio,
  StyledHomeImage,
  StyledHomeLinks,
  StyledHomeLine,
} from "./Home.styled";
import { LinkBox } from "../../components/LinkBox";
import { IMAGE_KEY } from "../../keys";
import { Resume } from "../../components/Resume";

const Home = ({ isOnload }) => {
  return (
    <StyledHome id="home">
      <StyledWrapper>
        <StyledHomeContent>
          <StyledHomeImage className={`${isOnload && "activated"}`}>
            <img
              src={`${IMAGE_KEY}/sunilpark_profile2.png?tr=w-300`}
              alt="profile picture"
              title="me"
            />
          </StyledHomeImage>
          <StyledHomeTextContainer>
            <StlyedHomeTitle className={`${isOnload && "activated"}`}>
              <span>Sunil Park</span>
              <h1>Frontend Developer</h1>
            </StlyedHomeTitle>
            <StyledHomeBio className={`${isOnload && "activated"}`}>
              <p>
                Hi, I'm Sunil Park, a Frontend Developer based in New Jersey,
                USA. I'm passionate about coding{" "}
                <span>
                  creatively, optimizing web performance, and crafting custom
                  UIs
                </span>
                . With a lifelong hobby in coding, I'm always eager to learn and
                grow. Let's build something amazing together!
              </p>
              <StyledHomeLine
                className={`${isOnload && "activated"}`}
              ></StyledHomeLine>
              <StyledHomeLinks className={`${isOnload && "activated"}`}>
                <LinkBox />
                <Resume />
              </StyledHomeLinks>
            </StyledHomeBio>
          </StyledHomeTextContainer>
        </StyledHomeContent>
      </StyledWrapper>
    </StyledHome>
  );
};

export default Home;
