/*
3D Cards
Created by Sunil Park
*/

import React, { useState } from "react";
import styled from "styled-components";
import { StyledBox, StyledUI, StyledUITextBox } from "./UI.styled";
import throttle from "../../../helper/throttle";

const StyledCard = styled.div`
  --main-clr: rgb(34, 34, 44);
  --mild-clr: rgb(165, 168, 175);
  --yellow-clr: rgb(150, 126, 99);

  justify-content: center;
  display: flex;
  transition: transform 0.3s;
  padding: 1em;
  height: 100%;
  width: 100%;
  user-select: none;
  transform-style: preserve-3d;
  perspective: 1600;
  touch-action: none;

  p {
    color: var(--mild-clr);
    font-size: 80%;
  }
  img {
    width: 15%;
  }

  &.card--active {
    transform: rotateY(180deg);
  }
`;

const StyledContainer = styled.div`
  position: relative;
  margin: auto;
  width: 100%;
  aspect-ratio: 8/5;
  transition: transform 0.3s;
  border-radius: 0.3rem;
  transform-style: preserve-3d;
`;

const StyledShadow = styled.div`
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 3;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.199) 30%,
    transparent,
    rgba(0, 0, 0, 0.918)
  );
  border-radius: 0.3rem;
`;

const StyledAspect = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.3rem;
  background-color: var(--main-clr);
  backface-visibility: hidden;
  overflow: hidden;
  transform: rotateX(0deg);
`;

const StyledFront = styled(StyledAspect)`
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 4% 2%;
  /* transform: translateZ(1px); */
`;

const StyledBack = styled(StyledAspect)`
  transform: rotateY(180deg);
  z-index: 1;
  padding-top: 10%;
`;

const StyledFrontColor = styled.div`
  position: absolute;
  z-index: -1;
  display: none;
  &:nth-of-type(1) {
    left: 30%;
    top: 20%;
    width: 30%;
    height: 100%;
    transform: rotate(25deg);
    background: linear-gradient(
      125deg,
      var(--main-clr) 20%,
      rgb(16, 17, 22) 80%
    );
  }
  &:nth-of-type(2) {
    right: -8%;
    top: 25%;
    width: 30%;
    height: 100%;
    transform: rotate(-65deg);
    background: linear-gradient(
      250deg,
      var(--main-clr) 20%,
      rgb(16, 17, 22) 80%
    );
  }
  &:nth-of-type(3) {
    right: -18%;
    top: -49%;
    width: 50%;
    height: 100%;
    transform: rotate(25deg);
    background: linear-gradient(
      70deg,
      var(--main-clr) 20%,
      rgb(16, 17, 22) 80%
    );
  }
`;

const StyledChip = styled.div`
  width: 15%;
  padding-bottom: 10%;
  background-color: rgb(223, 218, 203);
  border-radius: 0.2em;
  margin: 4.2%;
  position: relative;
  overflow: hidden;
  div {
    position: absolute;
    border: 1px solid rgba(155, 148, 49, 0.534);
    &:nth-of-type(1) {
      left: -10%;
      top: -10%;
      width: 55%;
      height: 41%;
      border-bottom-right-radius: 0.5em;
      background-color: rgba(216, 215, 205, 0.534);
    }
    &:nth-of-type(2) {
      left: -10%;
      top: 30%;
      width: 40%;
      height: 41%;
    }
    &:nth-of-type(3) {
      left: -10%;
      bottom: -10%;
      width: 55%;
      height: 41%;
      border-top-right-radius: 0.5rem;
    }
    &:nth-of-type(4) {
      right: -10%;
      top: -10%;
      width: 55%;
      height: 41%;
      border-bottom-left-radius: 0.5rem;
      background-color: rgba(226, 225, 216, 0.534);
    }
    &:nth-of-type(5) {
      right: -10%;
      top: 30%;
      width: 40%;
      height: 41%;
    }
    &:nth-of-type(6) {
      right: -10%;
      bottom: -10%;
      width: 55%;
      height: 41%;
      border-top-left-radius: 0.5rem;
    }
  }
`;

const StyledClientName = styled.div`
  margin-top: auto;
  margin-left: 4.2%;
  p {
    color: var(--mild-clr);
    text-shadow: 1px 1px 1px #0c0c0cc7;
  }
`;

const StyledLogo = styled.div`
  display: flex;
  align-items: center;

  p:nth-of-type(1) {
    font-size: 80%;
    color: var(--mild-clr);
  }
  p:nth-of-type(2) {
    font-size: 60%;
    color: var(--mild-clr);
  }
`;

const StyledWarn = styled.div`
  position: absolute;
  top: 2.5%;
  left: 2%;
  right: 2%;
  font-size: 45%;
  line-height: 45%;
  display: flex;
  justify-content: space-between;
`;

const StyledSlideArea = styled.div`
  position: absolute;
  left: 0;
  top: 6%;
  width: 100%;
  min-height: 13%;
  background-color: rgba(35, 48, 107, 0.486);
`;

const StyledCall = styled.div`
  padding: 3% 2%;
  padding-bottom: 1%;
  p {
    font-size: 40%;
  }
`;

const StyledSign = styled.div`
  position: relative;
  height: 18%;
  display: flex;
  justify-content: space-between;
  margin: 0% 2%;

  p {
    display: flex;
    right: 8%;
    align-items: center;
    position: absolute;
    font-size: 80%;
    color: var(--main-clr);
  }
  .line {
    height: 20%;
    width: 100%;
    background-color: rgba(75, 82, 117, 0.411);
  }
`;

const StyledLine = styled.div`
  position: relative;
  width: 68%;
  height: 100%;
  background-color: rgb(144, 152, 187);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const StyledSignBox = styled.div`
  position: absolute;
  right: 6%;
  width: 18%;
  aspect-ratio: 7/5;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 0.25rem;
  background-color: rgb(207, 207, 199);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 90%;
    height: 90%;
    fill: rgb(228, 228, 216);
  }
`;

const StyledInfo = styled.div`
  padding: 1% 2%;
  p {
    font-size: 28%;
  }
  .info {
    &.nums {
      line-height: 70%;
      padding: 1% 0;
    }
    p:nth-of-type(1) {
      font-size: 70%;
      color: var(--yellow-clr);
      text-shadow: 1px 1px 1px #0c0c0cc7;
    }
    p:nth-of-type(2) {
      font-size: 80%;
      color: var(--yellow-clr);
      text-shadow: 1px 1px 1px #0c0c0cc7;
    }
  }
`;

const StyledVisa = styled.div`
  position: absolute;
  padding: 1%;
  bottom: 4%;
  display: flex;
  flex-direction: column;
  svg {
    margin-left: auto;
    width: 25%;
    line-height: 0;
    margin-bottom: -1%;
    transform: translate(0rem, 30%);
    fill: rgb(197, 197, 175);
  }
  p {
    margin-right: 2%;
    margin-left: auto;
    font-size: 45%;
    color: rgb(197, 197, 175);
  }
`;

const StyledButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0.5em 1em;
  background-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.light};
  &:hover {
    background-color: ${({ theme }) => theme.colors.point};
  }
`;

const CardUI = () => {
  const [isCardFront, setCardFront] = useState(true);

  const cardClickHandler = () => {
    setCardFront(!isCardFront);
  };

  const [dragPos, setDragPos] = useState({
    start: { x: 0, y: 0 },
    current: { x: 0, y: 0 },
    end: { x: 0, y: 0 },
    isDragging: false,
  });

  /* Drag Start */
  function dragStartHandler(e) {
    // start at the last end position
    setDragPos((prev) => ({
      ...prev,
      start: {
        x: e.clientX + prev.end.x,
        y: e.clientY + prev.end.y,
      },
      isDragging: true,
    }));
  }

  /* Darg */
  function dragHandler(e) {
    if (dragPos.isDragging) {
      throttle(() => {
        // Record the current position value.
        // At the same time, prevents from passing more than 100px.
        setDragPos((prev) => ({
          ...prev,
          current: {
            x:
              prev.start.x - e.clientX > 100
                ? 99
                : prev.start.x - e.clientX < -100
                ? -99
                : prev.start.x - e.clientX,
            y:
              prev.start.y - e.clientY > 100
                ? 99
                : prev.start.y - e.clientY < -100
                ? -99
                : prev.start.y - e.clientY,
          },
        }));
      }, 100);
    }
  }

  /* Drag End */
  function dragEndHandler() {
    setDragPos((prev) => ({
      ...prev,
      end: {
        x: prev.current.x,
        y: prev.current.y,
      },
      isDragging: false,
    }));
  }

  return (
    <StyledUI>
      <StyledBox
        style={{
          outline: dragPos.isDragging ? "1px solid red" : "none",
          cursor: dragPos.isDragging ? "grabbing" : "grab",
        }}
        onPointerDown={dragStartHandler}
        onPointerMove={dragHandler}
        onPointerUp={dragEndHandler}
        onPointerLeave={dragEndHandler}
      >
        <StyledCard className={`${isCardFront && "card--active"}`}>
          <StyledContainer
            style={{
              transform: `rotateX(${dragPos.current.y / 3}deg) rotateY(${
                isCardFront ? dragPos.current.x / 3 : -dragPos.current.x / 3
              }deg)`,
              boxShadow: `${-dragPos.current.x / 10}px ${Math.abs(
                dragPos.current.y / 3
              )}px ${Math.abs(dragPos.current.y / 4)}px ${
                -(Math.abs(dragPos.current.y) + Math.abs(dragPos.current.x)) /
                10
              }px rgba(31, 31, 31, 0.459)`,
            }}
          >
            <StyledShadow
              style={{
                background: `linear-gradient(${
                  180 -
                  dragPos.current.y / 10 +
                  (isCardFront ? -dragPos.current.x / 5 : dragPos.current.x / 5)
                }deg, rgba(207, 207, 207, 0.26), rgba(255, 255, 255, 0.322) 20% ${
                  dragPos.current.y / 2
                }%, rgba(0, 0, 0, 0.219))`,
              }}
            />
            <StyledFront>
              <StyledFrontColor />
              <StyledFrontColor />
              <StyledFrontColor />
              <StyledLogo className="card-work__title">
                <img src="https://i.postimg.cc/cHnj3Wdz/logo.png" />
                <div>
                  <p>Spark</p>
                  <p>Developer Card</p>
                </div>
              </StyledLogo>
              <StyledChip>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </StyledChip>
              <StyledClientName>
                <p>Sunil Park</p>
              </StyledClientName>
            </StyledFront>
            <StyledBack>
              <StyledWarn>
                <p>Contains Metal DO NOT SHRED</p>
                <p>1111-22-333-00</p>
              </StyledWarn>
              <StyledSlideArea />
              <StyledCall>
                <p>spark.com | U.S 1-100-111-1234 | lnt'l: 1-100-123-1234</p>
              </StyledCall>
              <StyledSign>
                <StyledLine>
                  <p>321</p>
                  <div className="line"></div>
                  <div className="line"></div>
                  <div className="line"></div>
                </StyledLine>
                <StyledSignBox>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 579.365 579.365"
                  >
                    <g>
                      <g>
                        <path
                          d="M56.071,267.187c0.71,1.342,1.342,2.725,2.15,4.439c0.302,0.641,0.053,0.873-0.563,0.522
			c-6.202-3.545-11.942-6.826-17.654-10.094c-0.616-0.351-1.514-0.225-2.007,0.286c-0.167,0.171-0.331,0.343-0.498,0.514
			c-0.494,0.51-0.588,1.407-0.216,2.011c2.77,4.517,5.263,9.27,8.609,13.309c3.227,3.892,7.246,7.34,11.469,10.155
			c13.011,8.671,26.01,17.427,39.596,25.125c5.578,3.158,12.624,3.746,19.025,5.419c48.751,12.754,36.483,7.475,68.458,38.535
			c2.705,2.628,4.623,6.643,5.516,10.347c0.612,2.538-0.648,5.949-2.011,8.462c-6.936,12.795-18.335,21.444-29.09,30.69
			c-0.767,0.66-0.779,2.317-0.947,3.537c-1.183,8.629-2.746,17.238-3.301,25.908c-0.318,4.989,0.331,10.298,1.792,15.079
			c1.97,6.438,4.99,12.579,7.85,18.711c3.032,6.495,6.357,8.218,13.717,7.014c0.698-0.114,1.232-0.8,1.22-1.51
			c-0.184-13.574,8.38-22.538,17.511-31.53c9.915-9.763,21.506-10.253,33.746-8.434c0.702,0.106,1.461,0.739,1.677,1.412
			c4.961,15.431,1.134,30.951,0.763,46.406c-0.196,8.103-0.571,16.246-0.033,24.316c0.771,11.51-2.562,21.481-9.515,30.421
			c-3.696,4.749-7.238,5.271-10.963,0.607c-3.244-4.063-5.594-8.955-7.723-13.758c-2.419-5.455-5.451-6.821-8.907-3.537
			c-0.514,0.489-0.343,1.13,0.302,1.424c3.774,1.738,5.08,4.112,2.012,8.658c-0.918,1.358-0.029,5.201,1.35,6.609
			c3.337,3.395,7.299,6.177,11.416,9.408c0.555,0.437,0.44,0.914-0.257,1.028c-9.898,1.636-18.356-1.343-26.345-6.324
			c-7.964-4.965-15.178-2.016-16.614,6.41c-0.118,0.697,0.331,0.983,0.942,0.628c2.089-1.212,4.288-2.488,6.5-3.773
			c0.612-0.355,1.481-0.453,1.942-0.221c0.461,0.236,0.669,0.979,0.539,1.673c-0.93,4.994,2.13,5.997,6.218,6.557
			c3.774,0.514,7.405,2.011,11.159,2.786c3.166,0.657,6.397,1.008,9.87,2.351c0.661,0.253,0.636,0.661-0.041,0.864
			c-2.15,0.657-4.292,1.326-6.455,1.951c-5.308,1.538-10.788,2.644-15.892,4.684c-4.251,1.701-7.193,4.839-6.858,9.723
			c0.049,0.705,0.563,1.681,1.065,2.183c0.302,0.302,0.604,0.604,0.906,0.905c0.502,0.502,0.983,0.339,1.073-0.367
			c0.478-3.831,0.784-5.643,7.781-4.798c8.531,1.032,18.756-3.659,26.708-8.421c8.332-4.985,15.875-7.095,25.092-4.41
			c4.684,1.362,9.6,1.966,14.439,2.737c1.191,0.188,2.991,0.151,3.672-0.575c5.814-6.173,9.833-3.333,13.558,2.489
			c0.383,0.595,0.853,0.546,1.003-0.147c1.298-5.924-2.081-9.004-6.148-10.453c-4.011-1.428-9.053-2.183-13.019-1.053
			c-7.344,2.094-12.436-0.22-16.712-4.402c-0.506-0.493-0.587-1.399-0.208-1.999c9.319-14.602,18.393-28.816,27.462-43.023
			c0.384-0.596,0.372-1.411-0.028-1.815c-0.396-0.404-0.192-0.502,0.453-0.212c1.191,0.534,2.485,1.113,4.178,1.872
			c0.648,0.29,1.154-0.049,1.138-0.759c-0.082-3.121-0.155-5.838-0.229-8.653c-0.021-0.71,0.424-0.938,0.991-0.514
			c1.318,0.991,2.562,1.925,3.791,2.852c0.567,0.424,1.587,0.652,2.281,0.51c0.024-0.004,0.049-0.012,0.073-0.017
			c0.694-0.146,1.183-0.836,1.098-1.538c-0.481-3.859-0.963-7.723-1.448-11.632c-0.086-0.702,0.412-1.203,1.122-1.188
			c5.749,0.131,9.16-3.447,7.822-8.515c-2.163-8.156-5.194-16.079-7.385-24.228c-0.829-3.071-0.71-6.397-0.881-9.779
			c-0.037-0.706,0.469-1.109,1.146-0.901c0.583,0.18,1.15,0.354,1.77,0.542c0.678,0.208,1.073-0.179,0.89-0.86
			c-0.298-1.09-0.571-2.081-0.84-3.061c-0.188-0.686,0.236-1.22,0.942-1.195c3.288,0.103,6.418,0.2,9.869,0.307
			c0.71,0.02,1.44,0.591,1.628,1.276c6.63,23.697,12.269,47.3-6.557,69.303c-0.461,0.539-1.313,0.633-1.889,0.221
			c-2.497-1.783-5.431-3.876-9.303-6.638c-0.575-0.412-1.02-0.172-0.991,0.534c0.09,2.415,0.073,3.288,0.212,4.129
			c0.424,2.509,2.048,6.646,1.224,7.197c-2.195,1.469-5.08,1.901-7.825,2.55c-0.689,0.163-1.302-0.257-1.371-0.963
			c-0.041-0.38-0.078-0.759-0.114-1.139c-0.073-0.705-0.514-0.853-0.987-0.326c-1.102,1.22-2.256,2.501-3.656,4.048
			c-0.473,0.526-0.298,1.085,0.388,1.248c2.868,0.682,5.259,1.244,7.948,1.885c0.689,0.163,0.841,0.706,0.245,1.09
			c-5.427,3.46-14.631-2.024-16.643,8.229c-0.135,0.693,0.375,1.22,1.081,1.179c5.704-0.318,11.563,0.094,16.773-1.555
			c10.955-3.464,19.274-0.983,26.561,7.634c2.938,3.473,5.807,8.479,12.722,5.284c0.645-0.298,1.11-0.009,1.069,0.697
			c-0.135,2.383-0.249,4.321-0.343,6.034c-0.041,0.706,0.457,1.053,1.053,0.669c3.745-2.402,4.944-7.312,1.604-11.901
			c-2.126-2.917-5.708-4.839-8.809-6.952c-2.261-1.538-5.101-2.301-7.173-4.022c-7.259-6.034-6.858-7.969-0.188-14.378
			c6.703-6.442,12.64-13.681,19.008-20.481c0.882-0.942,2.122-1.547,4.06-2.754c0.601-0.376,1.2-1.253,1.347-1.946
			c0.404-1.897,0.946-4.48,1.538-7.267c0.147-0.693,0.702-0.882,1.24-0.42c1.18,1.016,2.265,1.95,3.484,2.998
			c0.539,0.461,1.102,0.277,1.294-0.403c0.265-0.931,0.489-1.641,0.437-2.334c-0.453-5.916,2.374-10.449,5.708-14.937
			c0.681-0.918,0.167-2.918-0.144-4.346c-0.86-3.974-1.868-7.915-2.893-12.122c-0.167-0.689,0.237-1.048,0.902-0.804
			c0.93,0.343,1.889,0.694,3.092,1.135c0.666,0.245,1.037-0.11,0.833-0.787c-1.388-4.574-2.604-8.576-3.954-13.02
			c-0.208-0.678,0.082-0.873,0.637-0.437c0.706,0.551,1.298,1.016,1.845,1.444c0.559,0.437,1.334,0.681,1.733,0.542
			c0.4-0.134,0.588-0.808,0.421-1.493c-0.739-3.068-1.481-6.141-2.224-9.225c-0.168-0.689,0.179-1.562,0.779-1.938
			c4.688-2.896,9.963-5.169,11.578-11.754c0.343-1.396,4.158-3.889,5.603-4.631c9.592-4.937,19.775-8.846,30.041-12.272
			c2.726-0.91,6.495,0.75,9.629,1.726c10.53,3.284,20.815,7.552,31.53,9.968c8.314,1.877,16.422-0.788,23.569-5.965
			c8.785-6.361,18.087-12.032,26.667-18.646c10.225-7.882,19.906-16.471,29.854-24.712c6.019-4.99,12.208-9.776,18.115-14.893
			c5.892-5.104,11.628-10.399,17.214-15.838c1.934-1.881,3.187-4.456,4.798-6.684c1.645-2.28,3.203-4.643,5.051-6.752
			c2.085-2.379,4.957-4.211,6.516-6.854c0.968-1.641,0.824-3.934,0.393-6.197c-0.135-0.698,0.004-1.763,0.326-2.396
			c3.104-6.132,2.089-7.283-4.19-12.289c-6.618-5.275-13.023-5.439-20.236-2.346c-25.704,11.008-51.441,21.935-77.125,32.992
			c-33.207,14.292-33.228,15.128-69.013,13.443c-20.768-0.979-41.375-0.906-61.935,1.008c-0.706,0.065-0.787-0.176-0.184-0.547
			c3.615-2.224,7.156-4.565,10.624-7.025c0.58-0.412,1.571-0.972,2.22-1.257c6.019-2.632,11.954-5.479,17.989-8.087
			c1.881-0.812,4.034-1.098,6.095-1.379c9.666-1.31,17.422-5.312,21.608-14.704c0.448-1.004,1.615-1.714,2.513-2.484
			c1.771-1.522,4.395-2.645,5.215-4.554c1.848-4.321,3.541-8.23,7.972-10.849c1.049-0.616,0.522-3.9,0.445-6.903
			c-0.021-0.71,0.547-1.465,1.187-1.767c3.158-1.494,4.395-4.068,3.207-9.319c-0.819-3.627,3.647-8.152,4.406-12.509
			c1.208-6.94,4.276-13.799,0.865-21.236c-1.579-3.452-1.13-7.826-1.66-11.771c-1.216-9.029-1.873-18.201-3.986-27.018
			c-0.845-3.521-3.974-6.5-6.083-9.106c-0.445-0.551-0.372-1.428,0.126-1.926c1.783-1.783,3.632-3.631,5.487-5.488
			c0.502-0.502,0.502-1.314,0-1.815c-2.048-2.052-4.12-4.121-6.336-6.34c-0.502-0.502-0.429-1.228,0.167-1.608
			c1.792-1.142,3.99-2.46,5.867-4.137c1.261-1.126,2.366-3.154,2.313-4.733c-0.032-0.906-2.424-2.052-3.929-2.518
			c-2.252-0.702-4.61-1.069-7.287-1.583c-0.697-0.134-0.791-0.587-0.221-1.003c4.436-3.211,8.507-6.157,12.547-9.082
			c0.575-0.417,1.012-1.306,0.975-1.987c-0.037-0.681-0.62-1.379-1.306-1.559c-3.814-1-7.654-2.007-11.738-3.08
			c-0.686-0.18-0.808-0.702-0.265-1.159c2.893-2.457,5.924-4.913,8.747-7.589c3.762-3.566,3.317-6.381-1.709-7.67
			c-3.791-0.975-8.111-1.22-11.931-0.453c-7.43,1.489-14.7,3.791-22.578,5.965c-0.682,0.188-0.779-0.008-0.217-0.437
			c34.99-26.573,36.899-28.086,37.426-35.137c0.054-0.706-0.432-1.032-1.072-0.726c-18.76,8.968-37.712,18.029-56.667,27.091
			c-0.641,0.306-0.755,0.146-0.249-0.351c18.588-18.482,39.327-34.77,51.485-59.348c0.314-0.637,0.094-0.82-0.485-0.412
			c-19.67,13.978-38.716,27.507-57.744,41.033c-0.58,0.412-1.311,0.514-1.637,0.224c-0.326-0.286-0.236-0.971,0.2-1.53
			c5.332-6.814,10.787-13.534,15.916-20.498c7.69-10.441,15.431-20.873,22.514-31.726c2.606-3.995,3.773-8.923,5.431-13.538
			c0.24-0.665,0.04-1.607-0.462-2.109l0,0c-0.501-0.502-1.31-0.494-1.803,0.017c-17.36,17.899-34.721,35.802-52.081,53.701
			c-0.494,0.51-0.951,0.779-1.028,0.608c-0.073-0.171,0.159-0.804,0.526-1.412c9.062-15.137,18.335-30.151,27.059-45.479
			c5.218-9.164,4.602-19.343,2.958-29.409c-0.082-0.506-0.481-0.963-1.236-1.571c-0.551-0.445-1.073-0.204-1.154,0.498
			c-3.154,26.998-23.636,42.493-39.769,61.062c-0.465,0.534-0.611,0.444-0.334-0.208c7.589-17.895,13.835-35.876,8.433-54.366
			c-0.199-0.682-0.583-0.694-0.832-0.029c-4.141,10.918-7.809,23.028-13.484,34.101c-5.781,11.281-13.562,21.542-21.036,32.052
			c-0.412,0.58-0.71,0.473-0.665-0.232c1.272-19.939,4.483-39.927-7.679-57.936c-0.396-0.588-0.686-0.481-0.62,0.224
			c0.355,3.896,0.844,7.793,0.906,11.693c0.261,16.463-3.427,31.983-9.474,47.381c-8.413,21.432-16.471,43.129-18.825,66.365
			c-0.286,2.807-1.061,5.561-1.808,8.919c-0.155,0.693-0.571,0.763-0.938,0.159c-1.771-2.905-3.346-5.492-5.365-8.804
			c-0.367-0.604-0.869-0.559-1.146,0.094c-1.057,2.464-2.174,4.247-2.044,5.928c0.559,7.335,0.934,14.818,2.726,21.901
			c2.913,11.498,6.997,22.697,10.351,34.088c0.51,1.734,0.743,3.476,1.612,4.77c0.396,0.587,0.669,1.416,0.433,1.999
			c-0.237,0.583-0.429,1.053-0.429,1.053c-0.22,0.677-0.44,1.354-0.665,2.036c-0.22,0.673-0.934,1.016-1.595,0.763
			c-1.118-0.428-2.228-0.861-3.28-1.338c-1.971-0.897-5.108-0.873-5.961-2.309c-4.619-7.801-10.833-7.414-18.152-4.504
			c-0.657,0.261-1.089-0.078-0.963-0.775c0.93-5.141,2.04-9.861,2.338-14.631c0.155-2.485-0.857-5.043-1.579-7.581
			c-0.196-0.681-0.873-1.061-1.55-0.853c-2.542,0.792-5.116,1.501-7.532,2.579c-1.358,0.604-2.477,1.763-4.047,3.129
			c-0.534,0.465-1,0.249-0.979-0.457c0.236-8.874,4.773-17.344-0.408-25.969c-0.367-0.608-1.142-0.714-1.693-0.269
			c-2.276,1.848-4.329,3.517-6.601,5.365c-0.551,0.449-1.004,0.236-1.012-0.474c-0.069-6.222-0.134-11.979-0.2-17.707
			c-0.008-0.71-0.514-1.477-1.13-1.714c-0.616-0.236-1.518-0.024-2.02,0.478c-4.174,4.174-8.352,8.348-12.525,12.525
			c-0.502,0.502-1.102,0.714-1.338,0.478c-0.236-0.236-0.31-0.991-0.159-1.685c1.657-7.667,3.313-15.333,4.974-22.999
			c0.151-0.693-0.22-1.55-0.828-1.917c-0.212-0.126-0.424-0.257-0.637-0.384c-0.608-0.367-1.53-0.285-2.077,0.167
			c-2.868,2.387-5.969,4.574-8.299,7.401c-6.744,8.192-13.104,16.695-19.6,25.088c-0.433,0.559-1.216,0.787-1.742,0.501
			c-0.526-0.285-0.75-1.048-0.506-1.713c1.036-2.803,1.995-5.643,3.231-8.352c4.251-9.282,8.788-18.438,12.876-27.789
			c0.787-1.803,0.583-4.039,0.073-6.434c-0.147-0.694-0.763-0.926-1.338-0.51c-15.537,11.265-21.657,29.935-35.513,42.991
			c-0.514,0.485-0.792,0.314-0.62-0.372c0.947-3.737,1.657-7.576,3.117-11.102c5.067-12.224,10.637-24.239,15.663-36.475
			c0.992-2.416,0.902-5.271,0.563-8.168c-0.082-0.702-0.681-1.028-1.31-0.698c-9.274,4.92-13.99,13.521-19.555,21.175
			c-5.455,7.499-10.147,15.549-15.129,23.395c-0.379,0.6-1.024,0.951-1.436,0.788c-0.412-0.164-0.58-0.849-0.379-1.526
			c5.708-19.237,11.416-38.474,17.124-57.711c0.204-0.681-0.163-1.46-0.808-1.746c-0.204-0.09-0.412-0.18-0.616-0.27
			c-0.649-0.286-1.624-0.159-2.167,0.298c-17.944,15.084-22.077,39.185-36.345,57.426c-0.437,0.559-0.775,0.428-0.767-0.282
			c0.041-3.66-0.261-7.397,0.563-10.869c3.452-14.549,7.561-28.943,10.971-43.497c0.853-3.635,0.457-7.561,0.453-11.396
			c0-0.71-0.526-1.473-1.196-1.706c-0.02-0.008-0.044-0.016-0.069-0.024c-0.669-0.232-1.587,0.012-2.068,0.535
			c-2.191,2.382-4.598,4.631-6.267,7.335c-11.134,18.042-16.458,38.356-22.326,58.438c-1.081,3.705-1.848,7.503-2.97,12.297
			c-0.163,0.69-0.539,0.73-0.824,0.082c-4.451-10.155-5.186-19.527-3.745-29.188c1.665-11.204,3.374-22.399,5.092-33.595
			c0.351-2.276,0.771-4.545,1.179-6.813c0.126-0.698-0.331-1.412-1.016-1.595c-0.135-0.037-0.265-0.069-0.4-0.106
			c-0.686-0.184-1.599,0.119-2.068,0.649c-1.885,2.122-4.109,4.117-5.059,6.597c-4.248,11.118-8.335,22.33-11.747,33.726
			c-2.685,8.976-4.411,18.237-6.5,27.389c-0.159,0.689-0.689,1.326-1.187,1.419c-0.498,0.094-0.996-0.396-1.114-1.097
			c-2.477-14.721-4.949-29.441-7.425-44.162c-0.118-0.698-0.788-1.31-1.493-1.363c-0.123-0.008-0.245-0.016-0.367-0.028
			c-0.706-0.053-1.473,0.445-1.726,1.105c-1.31,3.411-2.929,6.765-3.55,10.294c-3.292,18.723-2.729,37.536,0.588,56.149
			C30.351,210.353,41.379,239.443,56.071,267.187z"
                        />
                      </g>
                    </g>
                  </svg>
                </StyledSignBox>
              </StyledSign>
              <StyledInfo>
                <p>Use of this card is subject to the cardmember agreement.</p>
                <div className="info nums">
                  <p>Sunil Park</p>
                  <p>4869 7265 204D 6521</p>
                </div>
                <p>GOOD THRU</p>
                <div className="info">
                  <p>01/01</p>
                </div>
              </StyledInfo>
              <StyledVisa>
                <svg
                  viewBox="0 0 14 14"
                  role="img"
                  focusable="false"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M 9.7224981,5.365968 C 9.529,5.295318 9.2274981,5.220323 8.8525019,5.220323 7.8924981,5.220323 7.2150013,5.68871 7.21,6.359839 7.204,6.853388 7.6920013,7.13113 8.0605019,7.296613 c 0.3784994,0.169355 0.5049987,0.275807 0.504,0.428226 -0.00242,0.230807 -0.3025006,0.335323 -0.5810013,0.335323 -0.3830012,0 -0.5930012,-0.05177 -0.9154993,-0.181452 L 6.9485006,7.825 6.8130013,8.598225 c 0.2329974,0.09436 0.6529974,0.175162 1.0874981,0.181452 1.0204993,0 1.6875019,-0.465 1.6954993,-1.180161 C 9.6040087,7.206129 9.3409987,6.90758 8.7854994,6.661774 8.4485006,6.502096 8.2385006,6.395161 8.2385006,6.233064 c 0,-0.143226 0.1795007,-0.296129 0.5545007,-0.296129 0.3224981,-0.0048 0.5479974,0.06242 0.7274981,0.132097 l 0.09,0.03919 0.1354993,-0.747096 -0.023516,0.0048 z M 12.214,5.283708 l -0.75,0 c -0.233501,0 -0.408,0.06145 -0.510499,0.285968 L 9.511,8.731291 l 1.020499,0 0.204,-0.517742 1.245,9.68e-4 c 0.03048,0.120967 0.12,0.516774 0.12,0.516774 L 13,8.731291 12.214,5.28371 Z m -6.384,-0.02903 0.9715006,0 -0.6075019,3.449516 -0.9719988,0 L 5.83,5.25371 l 0,9.68e-4 z M 3.3604999,7.155807 3.4615,7.634839 4.4119999,5.28371 l 1.0295001,0 -1.5305001,3.442742 -1.0269999,0 -0.84,-2.915806 C 2.0265001,5.760807 2.005,5.726936 1.954,5.695968 1.6699999,5.552259 1.3525,5.435162 1,5.353872 l 0.0125,-0.07258 1.5655,0 c 0.2120001,0.0077 0.3830001,0.07258 0.4415001,0.292258 l 0.3409998,1.58371 0,-0.0015 z m 7.6539991,0.351774 0.3875,-0.964838 c -0.005,0.0097 0.08,-0.199355 0.129,-0.329033 l 0.0665,0.297581 0.224501,0.995322 -0.8075,0 0,9.68e-4 z" />
                </svg>
                <p>Signature</p>
              </StyledVisa>
            </StyledBack>
          </StyledContainer>
        </StyledCard>
      </StyledBox>
      <StyledUITextBox>
        <p>3D Card - drag</p>
        <StyledButton onClick={cardClickHandler}>Flip</StyledButton>
      </StyledUITextBox>
    </StyledUI>
  );
};

export default CardUI;
