import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
}

:root {
    --swiper-navigation-size: 22px;
    --swiper-navigation-top-offset: calc(100% - 2em);
    --swiper-navigation-sides-offset: 1em;
    --swiper-navigation-color: #dfdfdf;
}

a {
    color: black;
}

body{
    background-color: ${({ theme }) => theme.colors.light};
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: rgb(80, 80, 80);
    &:hover {
        background: rgb(163, 170, 189);
    }
}

.prevent-scroll {
    overflow: hidden;
}

h1,
h2,
h3,
h4,
p, 
a {
    color: ${({ theme }) => theme.colors.font};
}

h1 {
    font-size: 3em;
}

h2 {
    font-size: 2em;
}

h3 {
    font-size: 1.1em;
}

h4 {
    font-size: 1em;
}

p {
    font-size: 1em;
}

@media (max-width: ${({ theme }) => theme.tablet}) {
    body {
        font-size: .9rem;
    }
}

@media (max-width: ${({ theme }) => theme.mobile}) {
    body {
        font-size: .8rem;
    }
}

.modal-covered-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #000000b7;
    cursor: pointer;
    z-index: 998;
}

.swiper-button-prev,
.swiper-button-next {
    display: flex;
    z-index: 999;
    &::after {
        background-color: #00000076;
        padding: 1rem;
    }
    &:hover::after{
        background-color: #000000a7;
        color: #ffffff;
    }
}
`;
