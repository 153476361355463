import React, { useState } from "react";
import {
  StyledProfile,
  StlyedProfileContainer,
  StyledProfileSelected,
  StyledProfileGroups,
  StyledPhotoSlice,
} from "./Photo.styled";
import { IMAGE_KEY } from "../../keys";

const profileImgs = [
  "/profile_600x800.jpg",
  "/about_profile_large_7.jpg",
  "/about_profile_large_6.jpg",
  "/about_profile_large_1.jpg",
  "/about_profile_large_4.jpg",
  "/about_profile_large_5.png",
];

const LAST_THREE_LENGTH = profileImgs.length - 3;
const IMG_WIDTH = 70;
const BUTTON_WIDTH = 24;

const Photo = () => {
  const [selectedProfile, setSelectedProfile] = useState(0);
  const [leftPos, setLeftPos] = useState(0);
  const [transPos, setTransPos] = useState(0);

  function imgClickHandler(val, hasImgClicked) {
    let idx, left;

    if (!hasImgClicked) {
      idx = selectedProfile + val;
      if (val === 1) {
        left = false;
      } else {
        left = true;
      }
    } else {
      if (val === selectedProfile) return;
      idx = val;
      if (val > selectedProfile) {
        left = false;
      } else {
        left = true;
      }
    }

    if ((idx < 0 || idx > profileImgs.length - 1) && !hasImgClicked) return;

    if (idx < 2) {
      setLeftPos("0px");
    } else if (idx > LAST_THREE_LENGTH) {
      setLeftPos("100%");
    } else {
      setLeftPos((idx - 1) * -IMG_WIDTH + "px");
    }

    if (idx < 2) {
      setTransPos("0%");
    } else if (idx > LAST_THREE_LENGTH) {
      setTransPos("-100%");
    } else if (left) {
      setTransPos("0px");
    } else {
      setTransPos(IMG_WIDTH - BUTTON_WIDTH + "px");
    }

    setSelectedProfile(idx);
  }
  return (
    <StyledProfile>
      <StlyedProfileContainer>
        <StyledProfileSelected>
          {profileImgs.map((src, idx) => (
            <img
              className={`selected-image ${
                selectedProfile === idx && "active"
              }`}
              src={`${IMAGE_KEY}${src}?tr=h-550`}
              key={idx}
              alt="me"
            />
          ))}
        </StyledProfileSelected>
        <StyledProfileGroups>
          <button
            onClick={() => imgClickHandler(-1, false)}
            disabled={selectedProfile === 0}
          >
            <svg
              width="11"
              height="13"
              viewBox="0 0 11 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 6.06218L10.5 3.35573e-06L9.53363 2.92914C8.86234 4.9639 8.86234 7.16047 9.53364 9.19522L10.5 12.1244L0 6.06218Z"
                fill="black"
              />
            </svg>
          </button>
          <button
            onClick={() => imgClickHandler(1, false)}
            disabled={selectedProfile === profileImgs.length - 1}
          >
            <svg
              width="11"
              height="13"
              viewBox="0 0 11 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 6.06218L-5.29972e-07 12.1244L0.966364 9.19522C1.63766 7.16046 1.63766 4.96389 0.966364 2.92914L0 -4.5897e-07L10.5 6.06218Z"
                fill="black"
              />
            </svg>
          </button>

          {/* photos map */}
          <StyledPhotoSlice
            style={{
              left: `${leftPos}`,
              transform: `translateX(${transPos})`,
            }}
          >
            {profileImgs.map((item, idx) => {
              return (
                <div key={idx}>
                  <img
                    className={`${idx === selectedProfile && "selected-photo"}`}
                    src={`${IMAGE_KEY}${item}?tr=w-100`}
                    alt="me"
                    onClick={() => imgClickHandler(idx, true)}
                  />
                </div>
              );
            })}
          </StyledPhotoSlice>
        </StyledProfileGroups>
      </StlyedProfileContainer>
    </StyledProfile>
  );
};

export default Photo;
