import React, { useState, useEffect, useRef } from "react";
import { useScroll } from "../hooks/useScroll";
let pastScroll = 0;

export const NavbarScroll = () => {
    const { currentScroll } = useScroll();
    const [isScrollUp, setScrollUp] = useState(true);
    const unmounted = useRef(true);

    useEffect(() => {
        if (!unmounted.current) {
            if (pastScroll >= currentScroll) {
                setScrollUp(true);
            } else {
                setScrollUp(false);
            }
        }
        pastScroll = currentScroll;
        return () => {
            unmounted.current = false;
        };
    }, [currentScroll]);

    return { isScrollUp };
};