/*
The works created so far are shown on this component.
There is a Main button and a UI button, and each button displays different works. Main means website project and UI means CSS User Interface.
In the UI, I did not use any libraries except HTML, CSS, and React-JS.
*/

import React, { useEffect, useRef, useState } from "react";
import ProjectsMain from "./Main/ProjectsMain";
import ProjectsUI from "./ProjectsUI";
import { lists, UILists, threejs } from "./data";
import { StyledHeader } from "../../components/styles/Header.styled";
import useObserver from "../../hooks/useObserver";
import {
  StyledProjects,
  StyledProjectsContainer,
  StyledProjectsGap,
} from "./Projects.styled";
import { StyledWrapper } from "../../components/styles/Wrapper.styled";

import ProjectModal from "./ProjectModal";

const Projects = () => {
  const [modalData, setModalData] = useState(null);
  const targetRefHeader = useRef(null);
  const targetRefOne = useRef(null);
  const targetRefTwo = useRef(null);
  const targetRefThree = useRef(null);
  const defaultOption = {
    root: null,
    rootMargin: "0px 0px 0px 0px",
    threshold: 0.1,
  };
  const isVisibleHeader = useObserver(defaultOption, targetRefHeader, false);
  const isVisibleOne = useObserver(defaultOption, targetRefOne, false);
  const isVisibleThree = useObserver(defaultOption, targetRefThree, false);

  function getData(data) {
    setModalData(data);
    console.log(data);
  }

  useEffect(() => {
    if (modalData) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [modalData]);

  return (
    <StyledProjects id="projects">
      {/* <StyledLineTop /> */}
      <StyledWrapper>
        <StyledHeader
          ref={targetRefHeader}
          className={`${isVisibleHeader && "isActived"}`}
        >
          <h2>PROJECTS</h2>
          <span></span>
        </StyledHeader>

        <StyledProjectsGap>
          <StyledProjectsContainer
            ref={targetRefOne}
            className={`${isVisibleOne && "isActived"}`}
          >
            <ProjectsMain
              lists={lists}
              title={"Main Works"}
              getData={getData}
            />
          </StyledProjectsContainer>

          <StyledProjectsContainer
            ref={targetRefTwo}
            className={`${isVisibleOne && "isActived"}`}
          >
            <ProjectsMain
              lists={threejs}
              title={"3D Works"}
              getData={getData}
            />
          </StyledProjectsContainer>

          <StyledProjectsContainer
            ref={targetRefThree}
            className={`${isVisibleThree && "isActived"}`}
          >
            <ProjectsUI UILists={UILists} title={"Side Works"} />
          </StyledProjectsContainer>
        </StyledProjectsGap>
      </StyledWrapper>
      {modalData && (
        <>
          <ProjectModal
            modalData={modalData}
            setModalData={(boolean) => setModalData(boolean)}
          />
          <div
            className="modal-covered-bg"
            onClick={() => setModalData(null)}
          ></div>
        </>
      )}
      {/* <StyledLineBottom /> */}
    </StyledProjects>
  );
};

export default Projects;
