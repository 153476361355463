import React, { useEffect, useRef } from "react";
import {
  StyledProjectModal,
  StyledProjectModalImage,
  StyledProjectModalText,
} from "./Projects.styled";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { IMAGE_KEY } from "../../keys/keys";

// Import Swiper
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const ProjectModal = ({ modalData, setModalData }) => {
  const slideRef = useRef();
  const lastIndexRef = useRef();

  useEffect(() => {
    slideRef.current.children[0].children[2].focus();
  }, []);

  function focusHandler(index) {
    if (index === 1) {
      lastIndexRef.current.focus();
    }
    if (index === 2) {
      slideRef.current.children[0].children[1].focus();
    }
  }

  return (
    <StyledProjectModal>
      <div style={{}} tabIndex={0} onFocus={() => focusHandler(1)}></div>
      <StyledProjectModalImage ref={slideRef}>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
        >
          {modalData.front_post.map((item, idx) => (
            <SwiperSlide key={idx}>
              <img
                src={`${IMAGE_KEY}${item}?tr=w-800`}
                srcSet={`${IMAGE_KEY}${item}?tr=w-400 400w, ${IMAGE_KEY}${item}?tr=w-600 600w, ${IMAGE_KEY}${item}?tr=w-800 800w`}
                sizes="(max-width: 430px) 400px, (max-width: 630px) 600px, 800px"
                alt={modalData.title}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledProjectModalImage>
      <StyledProjectModalText>
        <div className="modal__header">
          <h3>{modalData.title}</h3>
          <p>{modalData.category}</p>
        </div>
        <div className="modal__desc">
          <ol>
            {modalData.desc.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ol>
          <div className="modal__tech-used">
            {modalData.tech.map((item) => (
              <p key={item}>{item}</p>
            ))}
          </div>
          <div className="modal__btns">
            <a href={modalData.link} target="_blank">
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> VIEW SITE
            </a>
            <a href={modalData.github} target="_blank">
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> VIEW GITHUB
            </a>
            <button ref={lastIndexRef} onClick={() => setModalData(null)}>
              CLOSE
            </button>
          </div>
        </div>
      </StyledProjectModalText>
      <div style={{}} tabIndex={0} onFocus={() => focusHandler(2)}></div>
    </StyledProjectModal>
  );
};

export default ProjectModal;
