/*
3D Boxs
Created by Sunil Park
*/

import React, { useState, useRef, useEffect } from "react";
import throttle from "../../../helper/throttle";
import styled from "styled-components";
import { StyledUI, StyledBox, StyledUITextBox } from "./UI.styled";

const StyledParent = styled.div`
  padding: 2rem;
  pointer-events: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.3s;
  touch-action: none;
`;

const StyledChild = styled.div`
  pointer-events: auto;
  width: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition-property: transform;
  transition-duration: 0.3s;

  &.actived {
    transform: rotateX(90deg) translateY(70%) rotateX(-90deg);
    transition-duration: 0.3s;
    .box-cover div {
      background-color: #bfdab9;
    }
    &--next {
      transform: rotateX(90deg) translateY(40%) rotateX(-90deg);
      transition-duration: 0.5s;
      .box-cover div {
        background-color: #dce7da;
      }
    }
  }

  .box-cover {
    pointer-events: none;
    width: 100%;
    position: relative;
    height: 100%;
    z-index: 1;
    transform-style: preserve-3d;
    transition-property: transform;
    transform: rotateX(90deg) translateY(0%) rotateX(-90deg);

    > div {
      transition-property: background-color;
      transition-duration: 0.8s;
      background-color: ${({ theme }) => theme.colors.light};
      outline: 2px solid rgba(139, 139, 139, 0.274);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  /* 3D box, currently disabled because of performance is too heavy */
  /* .box-left {
    transform: translateX(-50%) rotateY(90deg) translateX(50%);
    filter: brightness(70%);
  }
  .box-right {
    transform: translateX(50%) rotateY(90deg) translateX(50%);
    filter: brightness(70%);
  }
  .box-bottom {
    transform: rotateZ(90deg) translateX(50%) rotateY(90deg) translateX(50%);
    filter: brightness(50%);
  }
  .box-top {
    transform: rotateZ(-90deg) translateX(50%) rotateY(90deg) translateX(50%);
    filter: brightness(50%);
  } */
`;

const StyledButton = styled.div`
  display: flex;
  gap: 0.5rem;

  button {
    cursor: pointer;
    border: none;
    outline: none;
    padding: 0.5rem;
    background-color: ${({ theme }) => theme.colors.main};
    color: ${({ theme }) => theme.colors.light};
    &:hover {
      background-color: ${({ theme }) => theme.colors.point};
    }
  }
`;

const Boxs = () => {
  const parentRef = useRef(null);
  const [numBox, setNumBox] = useState(4);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [currentTargedBox, setCurrentTargetedBox] = useState(null);

  /* Add the box */
  function addBoxHandler(value) {
    resetClasses();

    // Set number of boxs
    if (value === "inc" && numBox < 9) {
      setNumBox((prev) => prev + 1);
    } else if (value === "dec" && numBox > 1) {
      setNumBox((prev) => prev - 1);
    }
  }

  /* Box Hover */
  function mouseMoveHandler(e) {
    const { value, str } = e.target.dataset;
    setCurrentTargetedBox({ value: value, str: str });

    throttle(() => {
      setMousePosition({
        x: e.clientX - parentRef.current.getBoundingClientRect().x - 200,
        y: e.clientY - parentRef.current.getBoundingClientRect().y - 120,
      });
    }, 100);
  }

  useEffect(() => {
    if (!currentTargedBox) return;
    const { value, str } = currentTargedBox;
    const parClass = parentRef.current.children;
    resetClasses();

    // Add animation
    if (!str.includes("left")) {
      parClass[Number(value) - 1].classList.add("actived--next");
    }
    if (!str.includes("right")) {
      parClass[Number(value) + 1].classList.add("actived--next");
    }
    if (!str.includes("top")) {
      parClass[Number(value) - numBox].classList.add("actived--next");
    }
    if (!str.includes("bottom")) {
      parClass[Number(value) + numBox].classList.add("actived--next");
    }
    parClass[Number(value)].classList.add("actived");
  }, [currentTargedBox?.value]);

  // Reset all className
  function resetClasses() {
    for (let i = 0; i < parentRef.current.children.length; i++) {
      parentRef.current.children[i].classList.remove("actived");
      parentRef.current.children[i].classList.remove("actived--next");
    }
  }

  function mouseLeaveHandler() {
    setTimeout(() => {
      for (let i = 0; i < parentRef.current.children.length; i++) {
        parentRef.current.children[i].classList.remove("actived");
        parentRef.current.children[i].classList.remove("actived--next");
      }
    }, 100);
  }

  return (
    <StyledUI>
      <StyledBox onMouseLeave={mouseLeaveHandler}>
        <StyledParent
          ref={parentRef}
          onPointerMove={mouseMoveHandler}
          style={{
            transform: `perspective(600px) rotateX(${
              -(mousePosition.y / 10) + 30
            }deg) rotateY(${mousePosition.x / 10 + 40}deg)`,
          }}
        >
          <DisplayBoxs numBox={numBox} />
        </StyledParent>
      </StyledBox>
      <StyledUITextBox>
        <p>3D Boxs - hover</p>
        <p>Current Boxs: {numBox ** 2}</p>
        <StyledButton>
          <button onClick={() => addBoxHandler("inc")}>Increase</button>
          <button onClick={() => addBoxHandler("dec")}>Decrease</button>
        </StyledButton>
      </StyledUITextBox>
    </StyledUI>
  );
};

/* Set boxs' value */
function DisplayBoxs({ numBox }) {
  if (!numBox) return;
  // Set total boxes to be displayed
  const boxArray = new Array(numBox ** 2).fill();

  // Calculate how many boxes will be lined up horizontally.
  const boxFlex = (100 - numBox) / numBox;

  return boxArray.map((_, idx) => {
    // Check if the hover's next box is exists.
    let str = "";
    if (idx % numBox === 0) {
      str = "left ";
    } else if (idx % numBox === numBox - 1) {
      str = "right ";
    }
    if (idx < numBox) {
      str = str + "top";
    } else if (idx >= numBox * (numBox - 1)) {
      str = str + "bottom";
    }

    return (
      <StyledChild
        key={idx}
        data-value={idx}
        data-str={str}
        style={{ flex: `1 1 ${boxFlex}%` }}
      >
        <div className="box-cover">
          <div></div>
        </div>
      </StyledChild>
    );
  });
}

{
  /* <div className="box-left"></div>
<div className="box-right"></div>
<div className="box-bottom"></div>
<div className="box-top"></div> */
}

export default Boxs;
