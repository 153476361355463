import styled, { keyframes } from "styled-components";
import { StyledWrapper } from "../styles/Wrapper.styled";

//============================= Navbar styling starts here ========================== //

export const StyledNavbar = styled.nav`
  display: flex;
  position: fixed;
  background-color: white;
  z-index: 997;
  width: 100%;
  box-shadow: 0px 0px 10px 5px rgba(29, 29, 29, 0.082);
  border-bottom: 1px solid rgb(241, 241, 241);
  top: 0;
  height: 100%;
  transition-property: transform, height;
  transition-duration: 0.3s, 1s;

  img {
    display: flex;
    margin: auto;
    height: 2rem;
  }

  &.isActived {
    transform: translateY(-100%);
  }

  li {
    pointer-events: none;
  }

  &.hasLoad {
    height: 3rem;

    li {
      pointer-events: auto;
    }
  }
`;

const LogoAni = keyframes`
  0% {
      transform: translateX(-50%) scale(1);
      opacity: 0;
  }
  15% {
      transform: translateX(-50%) scale(1.5);
      opacity: 1;
  }
  30% {
      transform: translateX(-50%) scale(0.8);
      opacity: 1;
  }
  45% {
    left: 50%;
      transform: translateX(-50%) scale(1);
      opacity: 1;
  }
  60% {
    left: 50%;
      transform: translateX(-50%) scale(1);
      opacity: 1;
  }
  80% {
    left: .5rem;
      transform: translateX(0) scale(1);
      opacity: 1;
  }
  100% {
    left: .5rem;
      transform: translateX(0) scale(1);
      opacity: 1;
  }
`;

export const StyledNavbarWrapper = styled(StyledWrapper)`
  flex-direction: row;
  position: relative;
  height: 3rem;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;

  > a {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition-property: left, transform;
    transition-duration: 1s;
    transition-delay: 0.5s;
    animation: 1.2s ${LogoAni} linear forwards;
    animation-delay: 0.3s;
    opacity: 0;
  }
`;

export const StyledNavbarLayoutBox = styled.ul`
  display: flex;
  gap: 1.5rem;
  margin-left: auto;

  li {
    opacity: 0;
    transform: translateY(-100%);
    transition-property: transform, opacity;
    transition-duration: 1s;
    transition-timing-function: ease-out;

    &:nth-of-type(1) {
      transition-delay: 0.5s;
    }
    &:nth-of-type(2) {
      transition-delay: 0.65s;
    }
    &:nth-of-type(3) {
      transition-delay: 0.8s;
    }
    &:nth-of-type(4) {
      transition-delay: 0.95s;
    }
  }
  &.hasLoad {
    li {
      opacity: 1;
      transform: translateY(0);
    }
  }

  a {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.font};
    &:hover {
      color: ${({ theme }) => theme.colors.point};
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: none;
  }
`;

export const StyledNavbarMenu = styled.div`
  display: none;
  position: relative;
  width: 2rem;
  height: 1.4rem;
  cursor: pointer;
  overflow: hidden;
  margin-left: auto;
  opacity: 0;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  transition-duration: 1s;
  transition-delay: 0.5s;
  transition-timing-function: ease-out;

  span {
    content: "";
    width: 100%;
    position: absolute;
    height: 0.25rem;
    transition-property: left, background-color;
    transition-duration: 0.3s;

    &:nth-of-type(1) {
      top: 0;
      left: 0;
      background-color: ${({ theme }) => theme.colors.dark};
      transform: translateY(0%);
    }
    &:nth-of-type(2) {
      top: 50%;
      left: 30%;
      background-color: ${({ theme }) => theme.colors.font};
      transform: translateY(-50%);
    }
    &:nth-of-type(3) {
      top: 100%;
      left: 60%;
      background-color: ${({ theme }) => theme.colors.main};
      transform: translateY(-100%);
    }
  }

  &.isActived {
    span {
      left: 0;
      background-color: ${({ theme }) => theme.colors.dark};
    }
  }

  &.hasLoad {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: flex;
  }
`;

export const StyledEgg__parent = styled.div`
  width: 10rem;
  height: 10rem;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;

  &.hasLoad {
    display: none;
  }
`;

const leftEggAni = keyframes`
  0% {
      transform: translate(0, -50%);
  }
  15% {
      transform: translate(0, -60%);
  }
  30% {
      right: 47%;
      transform: translate(0, -50%) rotate(0);
  }
  60% {
      right: 100%;
      transform: translate(0, -30%) rotate(-25deg) scale(1);
  }
  90% {
      right: 100%;
      transform: translate(0, -30%) rotate(-25deg) scale(1);
  }
  100% {
      right: 100%;
      transform: translate(0, -30%) rotate(-25deg) scale(0);
  }
`;

const rightEggAni = keyframes`
  0% {
    transform: translate(0, -50%);
  }
  15% {
    transform: translate(0, -60%);
  }
  30% {
    left: 47%;
    transform: rotate(0) translate(0, -50%);
  }
  60% {
    left: 100%;
    transform: translate(0, -30%) rotate(25deg) scale(1);
  }
  90% {
    left: 100%;
    transform: translate(0, -30%) rotate(25deg) scale(1);
  }
  100% {
    left: 100%;
    transform: translate(0, -30%) rotate(25deg) scale(0);
  }
`;

export const StyledEgg__child = styled.div`
  position: absolute;
  top: 50%;
  width: 40%;
  height: 50%;
  &:nth-of-type(1) {
    border-radius: 80% 0 0 80%/ 2rem 0 0 2rem;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.colors.main} 80%,
      transparent 20%
    );
    right: 47%;
    transform: translate(0, -50%);
    overflow: hidden;
    animation: 0.8s ${leftEggAni} forwards;
    > span {
      position: absolute;
      width: 25px;
      height: 120%;
      outline: none;
      border: none;
      background: linear-gradient(
          135deg,
          ${({ theme }) => theme.colors.main} 50%,
          transparent 0
        ),
        linear-gradient(
          45deg,
          ${({ theme }) => theme.colors.main} 50%,
          transparent 0
        );
      background-size: 25px 25px;
      top: 0;
      right: 0%;
    }
  }
  &:nth-of-type(2) {
    border-radius: 0 120% 120% 0 / 0rem 3rem 3rem 0rem;
    background: linear-gradient(
      -90deg,
      ${({ theme }) => theme.colors.main} 80%,
      transparent 20%
    );
    left: 47%;
    transform: translate(0, -50%);
    overflow: hidden;
    animation: 0.8s ${rightEggAni} forwards;
    > span {
      position: absolute;
      width: 25px;
      height: 120%;
      background: linear-gradient(
          -135deg,
          ${({ theme }) => theme.colors.main} 50%,
          transparent 0
        ),
        linear-gradient(
          -45deg,
          ${({ theme }) => theme.colors.main} 50%,
          transparent 0
        );
      background-size: 25px 25px;
      top: -12.5px;
      left: 0%;
    }
  }
`;

export const StyledNavbarMenuLinks = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 100%;
  justify-content: center;
  align-items: center;
  height: 15rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.light};
  border-bottom: 1px solid #dddddd;
  /* box-shadow: 0px 5px 10px -3px ${({ theme }) => theme.colors.shadow}; */
  transition-property: left;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  &.isActived {
    left: 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    a {
      display: flex;
      width: 5rem;
      height: 2rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.2rem;
      color: ${({ theme }) => theme.colors.light};
      background-color: ${({ theme }) => theme.colors.main};
      &:hover {
        background-color: ${({ theme }) => theme.colors.point};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: flex;
  }
`;

// ============================= NavbarAside styling starts here ============================= //

export const StyledAside = styled.aside`
  position: fixed;
  display: flex;
  flex-direction: column;
  opacity: 0;
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
  z-index: 9;
  width: 2rem;
  height: 14rem;
  align-items: center;
  justify-content: space-between;
  transition-property: transform, opacity;
  transition-duration: 1s;
  transition-delay: 2.4s;
  transition-timing-function: ease-out;

  &.hasLoad {
    transform: translate(-0.5rem, -50%);
    opacity: 1;
  }

  a {
    position: relative;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin: 0;
    background-color: ${({ theme }) => theme.colors.main};
    transition-property: padding, background-color, margin;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    z-index: 2;

    span {
      position: absolute;
      top: 50%;
      left: -0.5rem;
      color: ${({ theme }) => theme.colors.font};
      transform: translate(-100%, -50%);
      font-weight: bolder;
      font-size: 0.7rem;
      transition: 0.3s font-size ease-in-out;
    }

    &.isActived {
      background-color: ${({ theme }) => theme.colors.point};
      padding: 0.8rem;
      margin: 1rem 0;

      span {
        font-size: 0.8rem;
        color: ${({ theme }) => theme.colors.point};
      }
    }
  }

  @media (max-width: 1480px) {
    display: none;
  }
`;
