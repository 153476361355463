import styled from "styled-components";

export const StyledProfile = styled.div`
  display: flex;
  flex-direction: column;

  img {
    object-fit: cover;
  }
`;

export const StlyedProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledProfileSelected = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  max-width: 24rem;
  aspect-ratio: 5/7;
  width: 100%;
  background-color: #1f1f1f;

  .selected-image {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s;
    user-select: none;
    pointer-events: none;

    &.active {
      opacity: 1;
      user-select: initial;
      pointer-events: initial;
    }
  }
`;

export const StyledProfileGroups = styled.div`
  display: flex;
  position: relative;
  height: 6em;
  width: 20rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    filter: brightness(50%);
    cursor: pointer;
    transition: transform 0.3s, filter 0.3s;
    &:hover {
      filter: brightness(100%);
    }
  }

  .selected-photo {
    filter: brightness(100%);
    transform: scale(1.1);
  }

  button {
    position: absolute;
    z-index: 9;
    width: 2rem;
    background-color: rgb(238, 238, 238);
    color: #fff;
    border: none;
    cursor: pointer;
    :hover:not(:disabled) svg path {
      /* fill: ${({ theme }) => theme.colors.point}; */
    }
    &:nth-of-type(1) {
      left: 0;
      height: 100%;
    }
    &:nth-of-type(2) {
      right: 0;
      height: 100%;
    }

    &:disabled {
      opacity: 0.1;
      cursor: default;
    }
  }
`;

export const StyledPhotoSlice = styled.div`
  display: flex;
  position: absolute;
  padding: 0 2rem;
  height: 100%;
  align-items: center;

  > div {
    width: 70px;
    height: 70px;
    padding: 0.25rem;
  }
`;
