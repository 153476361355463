export const defaultTheme = {
  colors: {
    light: "rgb(252, 252, 252)",
    point: "#6d6fe0",
    // point: "#468eec",
    // point: "#83b175",
    main: "rgb(80, 80, 80)",
    font: "rgb(77, 77, 77)",
    dark: "rgb(34, 34, 44)",
  },
  mobile: "550px",
  tablet: "80rem",
};
