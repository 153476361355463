/*
The ways people can contact me are listed on this component.
*/

import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

import useObserver from "../../hooks/useObserver";
import { StyledWrapper } from "../../components/styles/Wrapper.styled";
import { StyledHeader } from "../../components/styles/Header.styled";
import { Resume } from "../../components/Resume";
import { LinkBox } from "../../components/LinkBox";
import {
  StyledContact,
  StyledContent,
  StyledContentTextBox,
  StyledContentForm,
  StyledContentLinkBox,
  StyledDotted,
} from "./Contact.styled";
import { StyledLineTop } from "../../components/styles/GlobalLine.styled";

const Contact = () => {
  const targetRefHeader = useRef(null);

  const [hasSent, setHasSent] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isError, setIsError] = useState(false);

  const defaultOption = {
    root: null,
    rootMargin: "0px 0px 0px 0px",
    threshold: 0.1,
  };
  const isVisibleHeader = useObserver(defaultOption, targetRefHeader, false);

  const form = useRef();

  function submitHandler(e) {
    e.preventDefault();
    setIsPending(true);
    setHasSent(false);
    setIsError(null);
    emailjs
      .sendForm(
        "service_q9ctm1r",
        "template_m19sshl",
        form.current,
        "pMCHdXMYKtUuz9-Jm"
      )
      .then(
        () => {
          setIsPending(false);
          setHasSent(true);
          setIsError(null);
          console.log("message has been sent");
        },
        (error) => {
          setIsPending(false);
          setIsError(error.text);
          console.log(error.text);
        }
      );
  }

  function status() {
    if (isPending) return <p>Pending...</p>;
    if (isError)
      return (
        <p>
          <span style={{ color: "red", fontWeight: "bold" }}>ERROR</span>:{" "}
          {isError}
        </p>
      );
    return hasSent ? (
      <p>Thank you! I received your message!</p>
    ) : (
      <p>I hope to have a day to work with you.</p>
    );
  }

  return (
    <StyledContact id="contact" ref={targetRefHeader}>
      <StyledLineTop />
      <StyledWrapper>
        <StyledHeader className={`${isVisibleHeader && "isActived"}`}>
          <h2>CONTACT</h2>
          <span></span>
        </StyledHeader>
        <StyledContent>
          <StyledContentTextBox className={`${isVisibleHeader && "isActived"}`}>
            <StyledDotted />
            <StyledDotted />
            <h3>Want to work together? Email me!</h3>
            <StyledContentForm ref={form} onSubmit={submitHandler}>
              <div>
                <label htmlFor="user_name">NAME</label>
                <input
                  type="text"
                  id="user_name"
                  name="user_name"
                  autoComplete="off"
                  spellCheck="false"
                  required
                />
              </div>
              <div>
                <label htmlFor="user_email">EMAIL</label>
                <input
                  type="email"
                  id="user_email"
                  name="user_email"
                  autoComplete="off"
                  spellCheck="false"
                  required
                />
              </div>
              <div>
                <label htmlFor="user_subject">SUBJECT</label>
                <input
                  type="text"
                  id="user_subject"
                  name="user_subject"
                  autoComplete="off"
                  spellCheck="false"
                  required
                />
              </div>
              <div>
                <label htmlFor="message">MESSAGE</label>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  required
                />
              </div>
              <button
                disabled={isPending}
                style={isPending ? { backgroundColor: "#aaaaaa" } : {}}
              >
                SEND
              </button>
              <p>Thank You!</p>
              {status()}
            </StyledContentForm>
          </StyledContentTextBox>
          <StyledContentTextBox className={`${isVisibleHeader && "isActived"}`}>
            <h3>Applicant Information</h3>
            <StyledDotted />
            <StyledDotted />
            <StyledContentLinkBox>
              <div>
                <p>
                  <span>Applicant Name</span>
                </p>
                <p>Sunil Park</p>
              </div>
              <div>
                <p>
                  <span>Position</span>
                </p>
                <p>Frontend Web Developer</p>
              </div>
              <div>
                <p>
                  <span>Email</span>
                </p>
                <p>sunilpark1129@gmail.com</p>
              </div>
              <div>
                <p>
                  <span>Links / Resume</span>
                </p>
                <div>
                  <LinkBox />
                  <Resume />
                </div>
              </div>
            </StyledContentLinkBox>
          </StyledContentTextBox>
        </StyledContent>
      </StyledWrapper>
    </StyledContact>
  );
};

export default Contact;
