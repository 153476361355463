/*
SVG Mobie Animation
SVG + CSS

Created by Sunil Park
*/

import React from "react";
import styled from "styled-components";
import { StyledUI, StyledBox, StyledUITextBox } from "./UI.styled";

const SVG = styled.svg`
  width: 100%;
  #screen {
    transition: all 0.3s ease-in-out;
    fill: rgb(119, 119, 119);
    transition-delay: 0.2s;
  }
  #articles,
  #pic1,
  #pic2,
  #pic3,
  #text1,
  #text2,
  #text3 {
    transition-property: opacity, transform;
    transition-duration: 0.1s, 0.3s;
    transition-timing-function: ease-in-out;
    opacity: 0;
    transition-delay: 0s;
  }
  #pic1,
  #pic2,
  #pic3 {
    transform: translateY(-8%);
  }
  #text1,
  #text3 {
    transform: translateX(8%);
  }
  #text2 {
    transform: translateX(-8%);
  }
  &:hover {
    #articles,
    #pic1,
    #pic2,
    #pic3,
    #text1,
    #text2,
    #text3 {
      transition: all 0.3s ease-in-out;
      opacity: 1;
    }
    #bluescreen2 {
      fill: rgb(91, 93, 230);
    }
    #screen {
      fill: #f3f3f3;
      transition-delay: 0s;
    }
    #articles {
      transition-duration: 0.6s;
      transition-delay: 0.2s;
    }
    #pic1 {
      transform: translateY(0%);
      transition-delay: 0.5s;
      transition-timing-function: cubic-bezier(0.42, 0, 0.68, 1.51);
    }
    #pic2 {
      transform: translateY(0%);
      transition-delay: 0.7s;
      transition-timing-function: cubic-bezier(0.42, 0, 0.68, 1.51);
    }
    #pic3 {
      transform: translateY(0%);
      transition-delay: 0.9s;
      transition-timing-function: cubic-bezier(0.42, 0, 0.68, 1.51);
    }
    #text1 {
      transform: translateX(0%);
      transition-delay: 0.5s;
    }
    #text2 {
      transform: translateX(0%);
      transition-delay: 0.7s;
    }
    #text3 {
      transform: translateX(0%);
      transition-delay: 0.9s;
    }
  }
`;

const Mobile = () => {
  return (
    <StyledUI>
      <StyledBox>
        <SVG
          width="452"
          height="452"
          viewBox="0 0 452 452"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="mobile">
            <rect
              id="case"
              x="116"
              y="20"
              width="220"
              height="412"
              rx="21"
              fill="#434343"
            />
            <rect
              id="screen"
              x="124"
              y="26"
              width="204"
              height="401"
              rx="20"
              fill="#F3F3F3"
            />
            <g id="articles">
              <rect
                id="article3"
                x="138"
                y="303"
                width="177"
                height="100"
                fill="#434343"
              />
              <rect
                id="article2"
                x="138"
                y="176"
                width="177"
                height="100"
                fill="#434343"
              />
              <rect
                id="article1"
                x="138"
                y="49"
                width="177"
                height="100"
                fill="#434343"
              />
            </g>
            <circle id="pic3" cx="174.5" cy="351.5" r="24.5" fill="#5DD377" />
            <circle id="pic2" cx="276.5" cy="226.5" r="24.5" fill="#5DD377" />
            <circle id="pic1" cx="174.5" cy="99.5" r="24.5" fill="#5DD377" />
            <g id="text3">
              <rect
                id="box"
                x="217"
                y="323"
                width="84"
                height="59"
                fill="white"
              />
              <line
                id="line"
                x1="226"
                y1="337.5"
                x2="292"
                y2="337.5"
                stroke="#8F8F8F"
              />
              <line
                id="line_2"
                x1="226"
                y1="365.5"
                x2="292"
                y2="365.5"
                stroke="#8F8F8F"
              />
              <line
                id="line_3"
                x1="226"
                y1="351.5"
                x2="292"
                y2="351.5"
                stroke="#8F8F8F"
              />
            </g>
            <g id="text2">
              <rect
                id="box_2"
                x="150"
                y="197"
                width="84"
                height="59"
                fill="white"
              />
              <line
                id="line_4"
                x1="159"
                y1="211.5"
                x2="225"
                y2="211.5"
                stroke="#8F8F8F"
              />
              <line
                id="line_5"
                x1="159"
                y1="239.5"
                x2="225"
                y2="239.5"
                stroke="#8F8F8F"
              />
              <line
                id="line_6"
                x1="159"
                y1="225.5"
                x2="225"
                y2="225.5"
                stroke="#8F8F8F"
              />
            </g>
            <g id="text1">
              <rect
                id="box_3"
                x="217"
                y="70"
                width="84"
                height="59"
                fill="white"
              />
              <line
                id="line_7"
                x1="226"
                y1="84.5"
                x2="292"
                y2="84.5"
                stroke="#8F8F8F"
              />
              <line
                id="line_8"
                x1="226"
                y1="112.5"
                x2="292"
                y2="112.5"
                stroke="#8F8F8F"
              />
              <line
                id="line_9"
                x1="226"
                y1="98.5"
                x2="292"
                y2="98.5"
                stroke="#8F8F8F"
              />
            </g>
            <g id="bluelight">
              <circle id="bluescreen2" cx="305" cy="37" r="4" fill="#B2BCF1" />
              <circle id="bluescreen1" cx="288" cy="37" r="4" fill="#B2BCF1" />
            </g>
          </g>
        </SVG>
      </StyledBox>
      <StyledUITextBox>
        <p>Figma SVG - hover</p>
      </StyledUITextBox>
    </StyledUI>
  );
};

export default Mobile;
