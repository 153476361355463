let throttleTimer;

function throttle(cb, delay) {
    if (throttleTimer) return;
    throttleTimer = true;
    setTimeout(() => {
        cb();
        throttleTimer = false;
    }, delay);
}

export default throttle;