/*
This page links to all sections and get all sections' scroll position as refs.
*/

import React, { useRef } from "react";
import { Home, About, Projects, Contact } from "./";
import styled from "styled-components";
import NavbarAside from "../components/Navbar/NavbarAside";

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
`;

const Pages = ({ isOnload }) => {
  // Save current position as a ref
  const targetRefOne = useRef(null);
  const targetRefTwo = useRef(null);
  const targetRefThree = useRef(null);
  const targetRefFour = useRef(null);

  // Send all positions to NavbarAside as an array
  const targetArray = [
    targetRefOne,
    targetRefTwo,
    targetRefThree,
    targetRefFour,
  ];

  return (
    <StyledMain id="evrey-parent">
      <NavbarAside isOnload={isOnload} targetArray={targetArray} />
      <div ref={targetRefOne}>
        <Home isOnload={isOnload} />
      </div>
      <div ref={targetRefTwo}>
        <Projects />
      </div>
      <div ref={targetRefThree}>
        <About />
      </div>
      <div ref={targetRefFour}>
        <Contact />
      </div>
    </StyledMain>
  );
};

export default Pages;
