import line from "../../assets/section_line.png";

import styled from "styled-components";

export const StyledLineTop = styled.div`
  /* position: absolute; */
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4rem;
  background-image: url(${line});
  background-repeat: repeat;
  background-size: contain;
  background-color: ${({ theme }) => theme.colors.light};
`;

export const StyledLineBottom = styled.div`
  /* position: absolute; */
  left: 0;
  top: 0;
  width: 100%;
  height: 4rem;
  background-image: url(${line});
  background-repeat: repeat;
  background-size: contain;
  transform: rotateX(180deg);
  background-color: ${({ theme }) => theme.colors.light};
`;
