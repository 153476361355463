import homeBg from "../../assets/home.jpg";
import styled, { keyframes } from "styled-components";

export const StyledHome = styled.section`
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow: hidden;
`;

export const StyledHomeContent = styled.div`
  display: flex;
  gap: 4em;
  justify-content: center;
  align-items: center;
  padding: 2em;

  @media (max-width: 620px) {
    gap: 2em;
    flex-direction: column;
  }
`;

export const StyledHomeTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;

  @media (max-width: 620px) {
    gap: 2em;
  }
`;

export const StlyedHomeTitle = styled.div`
  span {
    font-size: 2em;
    color: #2316ad;
    font-weight: bolder;
  }

  h1 {
    color: #838383;
    font-weight: 400;
    font-size: 1.2em;
  }

  span,
  h1 {
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
    transform: translateX(2rem);
    transition-delay: 1.7s;
    opacity: 0;
  }

  &.activated {
    span,
    h1 {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media (max-width: 620px) {
    text-align: center;
  }
`;

export const StyledHomeBio = styled.div`
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 1em;

  p {
    font-size: 1.2em;
    line-height: 1.4em;
    color: #828282;
  }

  span,
  p {
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
    transform: translateX(2rem);
    transition-delay: 1.8s;
    opacity: 0;
  }

  &.activated {
    span,
    p {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media (max-width: 620px) {
    text-align: center;
  }

  span {
    background-color: ${({ theme }) => theme.colors.point};
    color: #fff;
  }
`;

export const StyledHomeImage = styled.div`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.point};
  max-width: 300px;
  width: 100%;
  min-width: 220px;
  /* transform: scale(0);
  transition: transform 0.8s cubic-bezier(0, 0.2, 0.4, 1.77);
  transition-delay: 1.6s; */

  transition: opacity 1s ease-in-out;
  transition-delay: 1.6s;
  opacity: 0;

  &.activated {
    opacity: 1;
  }

  @media (max-width: 620px) {
    text-align: center;

    max-width: 200px;
    min-width: 150px;
  }

  /* &.activated {
    transform: scale(1);
  } */

  img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledHomeLinks = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;

  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  transform: translateY(1rem);
  transition-delay: 2s;
  opacity: 0;

  &.activated {
    transform: translateY(0);
    opacity: 1;
  }

  @media (max-width: 620px) {
    justify-content: center;
  }
`;

export const StyledHomeLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;

  transition: opacity 1s ease-in-out;
  transition-delay: 2s;
  opacity: 0;

  &.activated {
    opacity: 1;
  }
`;
