import React, { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { defaultTheme } from "../styles/Theme";
import { NavbarScroll } from "../../helper/ScrollHelper";
import logo from "../../assets/main_logo.png";
import {
  StyledNavbar,
  StyledNavbarWrapper,
  StyledNavbarLayoutBox,
  StyledNavbarMenu,
  StyledEgg__parent,
  StyledEgg__child,
  StyledNavbarMenuLinks,
} from "./Navbar.styled";

const layout = [
  {
    path: "#home",
    label: "Home",
  },
  {
    path: "#projects",
    label: "Projects",
  },
  {
    path: "#skills",
    label: "Skills",
  },
  {
    path: "#contact",
    label: "Contact",
  },
];

const Navbar = ({ isOnload }) => {
  const { isScrollUp } = NavbarScroll();
  const [hasLoad, setLoad] = useState(false);
  const [hasMenuTrigger, setMenuTrigger] = useState(false);

  /* Count down trigger for transition css */
  useEffect(() => {
    if (isOnload) {
      setTimeout(() => {
        setLoad(true);
      }, [1200]);
    }
  }, [isOnload]);

  useEffect(() => {
    window.addEventListener("resize", resizer);
    resizer();
    return () => {
      window.removeEventListener("resize", resizer);
    };
  }, []);

  /* Menu trigger is false when it's not mobile size */
  function resizer(e) {
    if (
      e &&
      e.currentTarget.innerWidth >
        Number(defaultTheme.mobile.substring(0, defaultTheme.mobile.length - 2))
    ) {
      setMenuTrigger(false);
    }
  }

  /* Display Egg Animation */
  const displayEgg = () => {
    return (
      <StyledEgg__parent className={`${hasLoad ? "hasLoad" : ""}`}>
        <StyledEgg__child>
          <span></span>
        </StyledEgg__child>
        <StyledEgg__child>
          <span></span>
        </StyledEgg__child>
      </StyledEgg__parent>
    );
  };

  /* Display layout for section links */
  const displayLayouts = () => {
    return (
      <StyledNavbarLayoutBox className={`${hasLoad ? "hasLoad" : ""}`}>
        {layout.map(({ path, label }) => {
          return (
            <li key={label}>
              <Link
                smooth
                to={path}
                title={`go to ${label.toLowerCase()} section`}
              >
                {label}
              </Link>
            </li>
          );
        })}
      </StyledNavbarLayoutBox>
    );
  };

  /* Display Menu-Bar when it reached the mobile size width */
  const displayMenu = () => {
    return (
      <>
        <StyledNavbarMenu
          onClick={() => {
            setMenuTrigger(!hasMenuTrigger);
          }}
          className={`${hasLoad ? "hasLoad" : ""} ${
            hasMenuTrigger ? "isActived" : ""
          }`}
        >
          <span></span>
          <span></span>
          <span></span>
        </StyledNavbarMenu>
      </>
    );
  };

  /* Display links when Menu-Bar is actived */
  const displayMenuLinks = () => {
    return (
      <StyledNavbarMenuLinks className={`${hasMenuTrigger && "isActived"}`}>
        <ul>
          {layout.map(({ path, label }) => {
            return (
              <li key={label}>
                <Link
                  smooth
                  to={path}
                  title={label}
                  onClick={() => setMenuTrigger(false)}
                >
                  {label}
                </Link>
              </li>
            );
          })}
        </ul>
      </StyledNavbarMenuLinks>
    );
  };

  return (
    <header>
      <StyledNavbar
        className={`${hasLoad ? "hasLoad" : ""} ${
          !isScrollUp && !hasMenuTrigger ? "isActived" : ""
        }`}
      >
        {displayMenuLinks()}
        <StyledNavbarWrapper>
          <Link
            smooth
            to="#"
            title="HOME"
            className={`${hasLoad ? "hasLoad" : ""}`}
          >
            <img src={logo} alt="main logo" />
          </Link>
          {displayLayouts()}
          {displayMenu()}
          {displayEgg()}
        </StyledNavbarWrapper>
      </StyledNavbar>
    </header>
  );
};

export default Navbar;
