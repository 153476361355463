import styled from "styled-components";

export const StyledUI = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: auto;
  max-width: 300px;
  border: 1px solid #b1b1b1;
`;

export const StyledBox = styled.div`
  display: flex;
  width: 100%;
  aspect-ratio: 1;
  max-width: 16em;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  margin: 1em auto;
  border: 1px solid #dfdfdf;
`;

export const StyledUITextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  height: 6rem;
  justify-content: center;
  border-top: 1px solid #d6d6d6;

  input {
    width: 8em;
    text-align: center;
    padding: 0.2rem 1rem;
    border: 1px solid #919191;
    outline: transparent;
    background-color: #ececec;

    &:focus {
      background-color: white;
      border: 1px solid #3d3d3d;
    }
  }
`;
